import React from 'react';

import { Collapsible } from 'components/CollapsibleContent';
import ChunkItem from 'connected-components/ChunkItem';
import { AssistantMessage } from 'services/assistant/assistant.types';

import './chunk-renderer.scss';

interface Props {
  message: AssistantMessage;
}
const ChunkRenderer = ({ message }: Props) => {
  if (!message.payload?.ref_chunks) {
    return null;
  }

  return (
    <div className="chunk-renderer">
      {message.payload.ref_chunks.map((chunk, i) => (
        <Collapsible sameStyling={true} key={i}>
          <Collapsible.CollapsedHeader>
            <div className="chunk-title">
              <span>
                {chunk.doc_name}, page {chunk.page_num} (score: {chunk.score})
              </span>

              <span>{chunk.chunk_type}</span>
            </div>
          </Collapsible.CollapsedHeader>

          <Collapsible.Content>
            <ChunkItem key={i} chunk={chunk} showHeader={false} />
          </Collapsible.Content>
        </Collapsible>
      ))}
    </div>
  );
};

export default ChunkRenderer;
