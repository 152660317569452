import {
  finishUpload,
  initUpload,
  upload as repoUpload,
  get as repoList,
  remove as repoRemove,
  processDoc as repoProcessDoc,
  search as repoSearch,
} from './upload.repo';
import { DocType, MIMEType, UploadId } from './upload.types';

const CHUNK_SIZE = 1 * 1024 * 1024 * 0.9; // 1MB chunk

type ProgressCallback = (index: number, total: number) => void;

const splitByLine = (text: string, maxChunkSize: number) => {
  const lines = text.split('\n');
  const chunks = [];
  let currentChunk = '';

  for (const line of lines) {
    if ((currentChunk + line).length > maxChunkSize) {
      chunks.push(currentChunk);
      currentChunk = '';
    }

    currentChunk += line + '\n';
  }

  if (currentChunk.length > 0) {
    chunks.push(currentChunk);
  }

  return chunks;
};

const readAsBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64String = (reader?.result as string).split(',')[1];

      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};

const readAsText = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

const txtUploader = async (file: File, fileName: string, onProgress: ProgressCallback) => {
  const content = await readAsText(file);

  const totalChunks = Math.ceil(content.length / CHUNK_SIZE);
  let currentChunkIndex = 0;

  const chunks = splitByLine(content, CHUNK_SIZE);

  const uploadId = await initUpload(fileName, DocType[MIMEType.TXT], chunks.length);

  for (const chunk of chunks) {
    onProgress(currentChunkIndex, totalChunks);

    try {
      await repoUpload(chunk, uploadId, currentChunkIndex);
    } catch {
      throw new Error('upload-failed');
    }

    currentChunkIndex++;
  }

  await finishUpload(uploadId);

  onProgress(totalChunks, totalChunks);
};

const pdfUploader = async (file: File, name: string, onProgress: ProgressCallback) => {
  const uploadId = await initUpload(name, DocType[file.type as MIMEType], 1);

  try {
    const content = await readAsBase64(file);
    onProgress(0, 1);

    await repoUpload(content, uploadId, 0);
  } catch (e) {
    throw new Error((e as any).response?.data.message || 'Error uploading file');
  }

  await finishUpload(uploadId);

  onProgress(1, 1);
};

export const upload = async (file: File, name: string, onProgress: ProgressCallback) => {
  if (file.type === MIMEType.TXT) {
    await txtUploader(file, name, onProgress);
  } else if (file.type === MIMEType.PDF) {
    await pdfUploader(file, name, onProgress);
  } else if (file.type === MIMEType.JSON) {
    await pdfUploader(file, name, onProgress);
  }
};

export const list = async (page: number, pageSize: number) => {
  const docs = await repoList(page, pageSize);
  return docs;
};

export const remove = async (id: UploadId) => {
  await repoRemove(id);
};

export const processDoc = async (id: UploadId) => {
  await repoProcessDoc(id);
};

export const search = async (question: string) => {
  const searchResult = await repoSearch(question);

  return searchResult;
};
