import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import CFButtonGroup, { ButtonGroupOption } from 'components/CFButtonGroup';
import { FilterGroupOperation, Tree } from 'services/cohort/cohort.types.api';

import LeafBuilder, { LeafRef } from 'connected-components/LeafBuilder';
import { Trait, TraitSubject } from 'domain/traits.types';
import CFAddButton from 'components/buttons/CFAddButton';

import FilterSet from 'services/cohort/domain/FilterSet';
import './tree-builder.scss';

export interface TreeBuilderRef {
  value: () => FilterSet;
}

interface Props {
  traits: Trait[];
  subject: TraitSubject;
  defaultValue?: Tree;
}

const TreeBuilder = forwardRef<TreeBuilderRef, Props>(function TreeBuilder(
  { traits, subject, defaultValue }: Props,
  ref
) {
  const [operatorOption, setOperatorOption] = useState<ButtonGroupOption>({
    label: 'AND',
    value: FilterGroupOperation.And,
  });

  const nodesRef = useRef<LeafRef[]>([]);
  const [nodeItems, setNodeItemsList] = useState<number[]>([]);

  useImperativeHandle(ref, () => {
    return {
      value() {
        return new FilterSet({
          nodes: nodesRef.current.filter((leafRef) => !!leafRef).map((leafRef) => ({ leaf: leafRef.value() })),
          op: operatorOption.value,
        });
      },
    };
  });

  useEffect(() => {
    nodesRef.current = [];
  }, [traits]);

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    setNodeItemsList(defaultValue.nodes.map((_, i) => i));
  }, [defaultValue]);

  const handleSelectOperator = useCallback((operatorOption: any) => {
    setOperatorOption(operatorOption);
  }, []);

  const handleNewGroup = useCallback(() => {
    setNodeItemsList((nodeItems) => [...nodeItems, new Date().valueOf()]); // save the new position
  }, []);

  const handleRemoveGroup = useCallback(
    (index: number) => {
      setNodeItemsList((nodeItems) => {
        const newItems = [...nodeItems];
        newItems.splice(index, 1);
        return newItems;
      });
    },
    [nodeItems]
  );

  return (
    <div className="tree-builder">
      <div className="inline">
        <CFButtonGroup
          options={[
            {
              label: 'AND',
              value: FilterGroupOperation.And,
            },
            {
              label: 'OR',
              value: FilterGroupOperation.OR,
            },
          ]}
          value={operatorOption}
          onSelect={handleSelectOperator}
        />

        <CFAddButton value={'Add new group'} onClick={handleNewGroup} />
      </div>

      {Array(nodeItems.length)
        .fill('')
        .map((_, i) => (
          <LeafBuilder
            key={nodeItems[i]}
            allowAllModels={true}
            defaultFilters={defaultValue?.nodes[i].leaf}
            ref={(el) => (nodesRef.current[i] = el as LeafRef)}
            subject={subject}
            traits={traits}
            showInitialFilter={true}
            onGroupRemoved={() => handleRemoveGroup(i)}
            onLeafChange={function (): void {
              //
            }}
          />
        ))}
    </div>
  );
});

export default TreeBuilder;
