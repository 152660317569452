import React, { useCallback } from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { AssistantMessage, AssistantMessageType, QuestionType } from 'services/assistant/assistant.types';
import { useServicesContext } from 'hooks/useServicesContext';
import Thread from 'services/assistant/thread';

interface MessagesContextValue {
  messages: AssistantMessage[];
  waitingResponse: string[] | undefined;
  hasMoreMessages: boolean;
  embedded: boolean;

  currentMessage: string;
  setCurrentMessage: React.Dispatch<React.SetStateAction<string>>;

  selectedParentId: number | undefined;
  setSelectedParentId: React.Dispatch<React.SetStateAction<number | undefined>>;

  followUpLastMessage: () => void;

  addQuestion: (type: QuestionType, text: string, parentId: number | undefined) => Promise<void>;
  loadMore: () => Promise<void>;
  reset: () => Promise<void>;
  disambiguate: (type: QuestionType, message: AssistantMessage, key: string, value: string) => Promise<void>;
}

const MessagesContext = createContext<MessagesContextValue | undefined>(undefined);

interface Props extends React.PropsWithChildren {
  embedded?: boolean;
}

const ContextProvider: React.FC<Props> = ({ children, embedded = false }) => {
  const threadRef = useRef<Thread>();

  const { assistantService } = useServicesContext();

  const [messages, setMessages] = useState<AssistantMessage[]>([]);
  const [waitingResponse, setWaitingResponse] = useState<string[] | undefined>(undefined);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [currentMessage, setCurrentMessage] = useState('');

  const [selectedParentId, setSelectedParentId] = useState<number | undefined>();
  const initPromise = useRef<Promise<Thread>>();

  useEffect(() => {
    (async () => {
      initPromise.current = assistantService.getThread();
      const thread = await initPromise.current;
      threadRef.current = thread;

      const [hasMore, history] = await thread.history();

      setHasMoreMessages(hasMore);
      setMessages(history);
    })();
  }, []);

  const reset = async () => {
    await threadRef.current?.reset();
    setMessages([]);
  };

  const followUpLastMessage = () => {
    const lastMessage = messages[1];

    if (!lastMessage) {
      return;
    }

    setSelectedParentId(lastMessage.id);
  };

  const addQuestion = async (type: QuestionType, text: string, parentId: number | undefined) => {
    await initPromise.current;
    if (!threadRef.current) {
      return;
    }

    const question: AssistantMessage = {
      id: Math.random() * 1000,
      tid: 0,
      parentId,
      text: text,
      timestamp: '',
      type: AssistantMessageType.Question,
      error: false,
    };

    const handleProgress = (text: string) => {
      setWaitingResponse((prev) => [...(prev || []), text]);
    };

    // TODO: this filtering is already done at Thread level. When this thread is able to populate its
    // internal messages state won't be needed again
    setMessages((messages) =>
      [question, ...messages].filter((message) => message.type !== AssistantMessageType.ValidationRequest)
    );
    setWaitingResponse([]);

    const newMessages = await threadRef.current.askWithValidation(type, text, parentId, handleProgress);

    setMessages(newMessages);
    setWaitingResponse(undefined);
  };

  const disambiguate = async (type: QuestionType, message: AssistantMessage, key: string, value: string) => {
    await initPromise.current;
    if (!threadRef.current) {
      return;
    }

    const newMessages = await threadRef.current.disambiguate(type, message, key, value);
    setMessages(newMessages);
  };

  const loadMore = useCallback(async () => {
    if (!threadRef.current) {
      return;
    }

    if (!hasMoreMessages) {
      return;
    }

    const [hasMore, newMessages] = await threadRef.current.loadMore();

    setHasMoreMessages(hasMore);
    setMessages((messages) => [...messages, ...newMessages]);
  }, [hasMoreMessages]);

  return (
    <MessagesContext.Provider
      value={{
        messages,
        waitingResponse,
        hasMoreMessages,
        addQuestion,
        loadMore,
        reset,
        embedded,
        currentMessage,
        setCurrentMessage,
        disambiguate,
        selectedParentId,
        setSelectedParentId,
        followUpLastMessage,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessagesContext = (): MessagesContextValue => {
  const context = useContext(MessagesContext);
  if (!context) {
    throw new Error('useMessagesContext must be used within an MessagesContextProvider');
  }
  return context;
};

export default ContextProvider;
