import { AxiosResponse } from 'axios';
import { get as httpGet, post as httpPost, remove as httpDelete } from './drivers/http';

import {
  CreatingIntervention,
  InterventionDraft,
  NewInterventionRequest,
} from 'services/intervention/intervention.types';
import { RepoConfig } from './types';
import { Schedule } from 'services/scheduling/schedulting.types.api';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/inv/draft';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export interface CFInterventionDraftRepository {
  save: (intervention: CreatingIntervention, schedule: Schedule, draftId?: number) => Promise<number>;
  update: (intervention: CreatingIntervention, schedule: Schedule, draftId: number) => void;
  remove: (draftId: number) => void;
  get: () => Promise<InterventionDraft[]>;
}

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const save = async (intervention: CreatingIntervention, schedule: Schedule, draftId?: number) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      no_check: true,
    },
  };

  const body: NewInterventionRequest = {
    intervention,
    schedule: schedule,
    draft_id: draftId,
  };

  try {
    const data = (await httpPost(`${serverBaseUrl}${path}/save`, body, config)) as AxiosResponse;
    return data.data;
  } catch (err) {
    console.error('error saving draft: ', err);
    throw new Error('error-intervention-save');
  }
};

export const update = async (intervention: CreatingIntervention, schedule: Schedule, draftId: number) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      no_check: true,
    },
  };

  const body: NewInterventionRequest = {
    intervention,
    schedule: schedule,
    draft_id: draftId,
  };

  try {
    await httpPost(`${serverBaseUrl}${path}/update/${draftId}`, body, config);
  } catch (err) {
    console.error('error updating intervention draft: ', err);
    throw new Error('error-updating-intervention-draft');
  }
};

export const remove = async (draftId: number) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    await httpDelete(`${serverBaseUrl}${path}/delete/${draftId}`, config);
  } catch (err) {
    console.error('error deleting intervention draft: ', err);
    throw new Error('error-deleting-intervention-draft');
  }
};

export const get = async (): Promise<InterventionDraft[]> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const response = (await httpGet(`${serverBaseUrl}${path}/list`, config)) as AxiosResponse;

    return response.data || [];
  } catch (err) {
    console.error('error listing interventions: ', err);
    throw new Error('error-listing-interventions');
  }
};

export default {
  get,
  remove,
  save,
  update,
};
