import React, { useMemo } from 'react';

import { Ptr } from 'services/cohort/cohort.types.api';
import { getAggLevel } from 'services/traits/helpers.traits';

interface Props {
  windows?: number[];
  ptr: Ptr;
}

const CFWindowsInfo = ({ ptr, windows }: Props) => {
  const aggLevel = useMemo(() => {
    return getAggLevel(ptr);
  }, []);

  if (!windows || windows.length === 0) {
    return <></>;
  }

  return (
    <span>
      ({windows.join(', ')} {aggLevel}
      {windows.length > 1 && 's'})
    </span>
  );
};

export default CFWindowsInfo;
