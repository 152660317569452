import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import { InteractionType } from 'domain/model.types';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import CFTitledComponent from 'components/CFTitledComponent';
import TimeWindowPicker, { TimeWindowPickerRef } from 'components/TimeWindowPicker';
import CFSelect from 'components/CFSelect';

import { Steps } from '../useContext';

import './embedding-params.scss';

interface Props {}

export interface EmbeddingParamsRef {
  value: () => {
    interactionType: InteractionType;
    loobackTime: number;
  };
}

const EmbeddingParams = forwardRef<EmbeddingParamsRef, Props>(function EmbeddingParams({}: Props, ref) {
  const monitoringWindowRef = useRef<TimeWindowPickerRef | null>();
  const [interactionType, setInteractionType] = useState(InteractionType.Buy);

  useImperativeHandle(ref, () => ({
    value() {
      return {
        interactionType: interactionType as InteractionType,
        loobackTime: (monitoringWindowRef.current as TimeWindowPickerRef).value(),
      };
    },
  }));

  return (
    <InterventionSection name={Steps.Parameters} title={'Parameters'} subtitle="" className="params-reduced">
      <CFTitledComponent title="Interaction type">
        <CFSelect
          options={Object.values(InteractionType).map((key) => ({ label: key, value: key }))}
          onSelected={(option) => setInteractionType(option.value as InteractionType)}
          value={{ label: interactionType, value: interactionType }}
          isMulti={false}
        />
      </CFTitledComponent>

      <CFTitledComponent title="Lookback time">
        <TimeWindowPicker ref={(el) => (monitoringWindowRef.current = el)} />
      </CFTitledComponent>
    </InterventionSection>
  );
});

export default EmbeddingParams;
