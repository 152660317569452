import { CreateUploadDataAPI, DocAPI, RetrievedChunkAPI, UploadId, UploadSplitAPI } from './upload.types';
import {
  get as httpGet,
  post as httpPost,
  put as httpPut,
  remove as httpDelete,
} from '../../repositories/drivers/http';
import { AxiosResponse } from 'axios';
import { PaginatedElement } from 'types';
import { RepoConfig } from 'repositories/types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/knowledgebase';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export interface CFUploadRepository {
  init: ({ token, oid, pid }: RepoConfig) => void;
  initUpload: (title: string, totalSplits: number) => Promise<UploadId>;
  upload: (chunk: string, uploadId: UploadId, index: number) => Promise<void>;
}

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const initUpload = async (title: string, docType: string, totalSplits: number): Promise<UploadId> => {
  const config = {};

  const body: CreateUploadDataAPI = {
    doc_type: docType,
    title,
    total_split: totalSplits,
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/create?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    )) as AxiosResponse<UploadId>;

    return data.data;
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export const upload = async (chunk: string, uploadId: UploadId, index: number) => {
  const config = {};

  const body: UploadSplitAPI = {
    doc_id: uploadId,
    split_id: index,
    content: chunk,
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/upload?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    )) as AxiosResponse<UploadId>;

    return data.data;
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export const finishUpload = async (uploadId: UploadId) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };
  const body = {};

  try {
    await httpPut(
      `${serverBaseUrl}${path}/upload/finish/${uploadId}?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    );
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export const get = async (page: number, per_page: number): Promise<PaginatedElement<DocAPI>> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      page,
      per_page,
    },
  };

  try {
    const {
      data: { total, data },
    } = (await httpGet(`${serverBaseUrl}${path}/list`, config)) as AxiosResponse<PaginatedElement<DocAPI>>;

    return { total, data: data.map((item) => ({ ...item, doc_type: item.doc_type || 'txt' })) };
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export const processDoc = async (uploadId: UploadId) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  const body = {};

  try {
    await httpPut(
      `${serverBaseUrl}${path}/process/${uploadId}?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    );
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export const remove = async (uploadId: UploadId) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };
  try {
    await httpDelete(`${serverBaseUrl}${path}/delete/${uploadId}?oid=${repoConfig.oid}&pid=${repoConfig.pid}`, config);
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export const search = async (question: string): Promise<RetrievedChunkAPI[]> => {
  const config = {};

  const body = {
    question,
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/search?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    )) as AxiosResponse<RetrievedChunkAPI[]>;

    return data.data;
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export default {
  init,
  initUpload,
  upload,
  remove,
  processDoc,
};
