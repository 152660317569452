import React, { createContext, useState, useContext, useRef, useEffect } from 'react';

import { roundEdges } from 'helpers/dates';
import { useToast } from 'hooks';
import { useServicesContext } from 'hooks/useServicesContext';

import usersRepo from 'services/admin/users/users.repo'; // should avoid reference repo directly?

import { ColAddr, TraitSubject } from 'domain/traits.types';
import { Module, TimeRFC3999 } from 'domain/general.types';
import { AggLevel, TimeSeriesItem } from 'domain/stats.types';

import Cohort from 'services/cohort/domain/Cohort';
import { TraitExploreResp } from 'services/assistant/assistant.types';
import { Ptr } from 'services/cohort/cohort.types.api';

import { ToastType } from 'components/CFToast/types';

import useInitializedSubject from '../hooks/useInitializedSubject';
import useInitializedModule from '../hooks/useInitializedModule';
import useInitializedCohorts from '../hooks/useInitializedCohorts';
import useInitializedRange from '../hooks/useInitializedRange';
import useInitializedTraits, { TraitAction, TraitEventData, TraitsState } from '../hooks/useInitializedTraits';

import { NormalizationType } from '../types';

const PAGE_SIZE = 100;

interface AnalyticsContextValue {
  traitsState: TraitsState;
  dispatchTraitAction: React.Dispatch<TraitEventData>;

  explorationMode: boolean;
  setExplorationMode: React.Dispatch<React.SetStateAction<boolean>>;

  module: Module;
  setModule: React.Dispatch<React.SetStateAction<Module>>;

  subject: TraitSubject;
  setSubject: React.Dispatch<React.SetStateAction<TraitSubject>>;

  aggLevel: AggLevel;
  setAggLevel: React.Dispatch<React.SetStateAction<AggLevel>>;

  startDate: TimeRFC3999;
  setStartDate: React.Dispatch<React.SetStateAction<TimeRFC3999>>;

  endDate: TimeRFC3999;
  setEndDate: React.Dispatch<React.SetStateAction<TimeRFC3999>>;

  selectedCohortIDs: Ptr[];
  setSelectedCohortIDs: React.Dispatch<React.SetStateAction<Ptr[]>>;

  getSerie: (ptr: Ptr, cohortId: string) => Promise<TimeSeriesItem[]>;
  reset: () => Promise<void>;

  normalizationType: NormalizationType;
  setNormalizationType: React.Dispatch<React.SetStateAction<NormalizationType>>;

  setExplorationSearch: React.Dispatch<React.SetStateAction<TraitExploreResp | undefined>>;
  explorationSearch: TraitExploreResp | undefined;

  availableCohorts: Cohort[] | null;
}

const AnalyticsContext = createContext<AnalyticsContextValue | undefined>(undefined);

interface Props extends React.PropsWithChildren {}

const ContextProvider: React.FC<Props> = ({ children }) => {
  const { traitSessionService: traitService, cohortService } = useServicesContext();

  const [explorationSearch, setExplorationSearch] = useState<TraitExploreResp>();

  const [subject, setSubject] = useInitializedSubject();
  const [module, setModule] = useInitializedModule();
  const [explorationMode, setExplorationMode] = useState(false);

  const [traitsState, dispatchTraitAction] = useInitializedTraits();

  const [selectedCohortIDs, setSelectedCohortIDs] = useInitializedCohorts();

  const [startDate, endDate, setStartDate, setEndDate] = useInitializedRange();

  const [aggLevel, setAggLevel] = useState<AggLevel>(AggLevel.Day);

  const [availableCohorts, setAvailableCohorts] = useState<Cohort[] | null>(null);
  const initTimeseriePromise = useRef<Record<string, Promise<TimeSeriesItem[]>>>({});

  const [normalizationType, setNormalizationType] = useState(NormalizationType.CohortSubjects);

  const { addToast } = useToast();

  const generateTimeseriesCode = (cohortId: string, ptr: Ptr) => {
    return `${cohortId}-${ptr}-${startDate}-${endDate}`;
  };

  const generateTimeSeries = async (ptr: Ptr) => {
    try {
      for (const cohortID of selectedCohortIDs) {
        const timeSeriesKey = generateTimeseriesCode(cohortID, ptr);

        if (initTimeseriePromise.current[timeSeriesKey] !== undefined) {
          continue;
        }

        initTimeseriePromise.current[timeSeriesKey] = traitService.getTimeseries(
          startDate,
          endDate,
          traitService.getTraitDefinition(ptr)?.addr as ColAddr,
          cohortID
        );
      }
    } catch (error: any) {
      // TODO: should a context be responsible for showing an error
      addToast(`Couldn't load the charts data ${error.message}`, ToastType.ERROR, 5000);
    }
  };

  useEffect(() => {
    traitsState.traits[aggLevel]?.forEach((traitName) => {
      generateTimeSeries(traitName);
    });
  }, [aggLevel, selectedCohortIDs, traitsState]);

  useEffect(() => {
    (async () => {
      const cohortList = await cohortService.getListOfCohorts(0, PAGE_SIZE, subject);

      setAvailableCohorts(cohortList.data);
    })();
  }, [subject]);

  useEffect(() => {
    setSelectedCohortIDs((selectedCohortIDs) => {
      const newSelectedCohortIDs = selectedCohortIDs.filter((id) =>
        availableCohorts?.find((cohort) => `${cohort.id}` === id)
      );

      if (!newSelectedCohortIDs.length) {
        const all = availableCohorts?.find((cohort) => cohort.name.startsWith('all'));

        if (all) {
          newSelectedCohortIDs.push(`${all.id}`);
        }
      }

      return newSelectedCohortIDs;
    });
  }, [availableCohorts]);

  const getSerie = async (cohortId: string, ptr: Ptr) => {
    const code = generateTimeseriesCode(cohortId, ptr);

    const [roundedStart, roundedEnd] = roundEdges(startDate, endDate, aggLevel);

    if (initTimeseriePromise.current[code] === undefined) {
      initTimeseriePromise.current[code] = traitService.getTimeseries(
        roundedStart,
        roundedEnd,
        traitService.getTraitDefinition(ptr)?.addr as ColAddr,
        cohortId
      );
    }

    const timeserie = await initTimeseriePromise.current[code];

    return timeserie || [];
  };

  const reset = async () => {
    await usersRepo.updateGDTSettings(subject, module, []);

    const availableTraits = (await usersRepo.getGDTSettings(subject, module)).filter(
      (coldAddr) => traitService.getTraitFromAddr(coldAddr) !== undefined
    );

    const ptrs = availableTraits.map((colAddr: ColAddr) => colAddr.ptr);

    dispatchTraitAction({ action: TraitAction.LOAD, ptrs });
  };

  return (
    <AnalyticsContext.Provider
      value={{
        traitsState,
        dispatchTraitAction,

        explorationMode,
        setExplorationMode,

        module,
        setModule,

        subject,
        setSubject,

        aggLevel,
        setAggLevel,

        startDate,
        setStartDate,

        endDate,
        setEndDate,

        selectedCohortIDs,
        setSelectedCohortIDs,
        availableCohorts,

        getSerie,
        reset,

        normalizationType,
        setNormalizationType,

        setExplorationSearch,
        explorationSearch,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = (): AnalyticsContextValue => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalyticsContext must be used within an AnalyticsContextProvider');
  }
  return context;
};

export default ContextProvider;
