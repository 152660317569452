import React, { useCallback, useState } from 'react';

import CFButton from 'components/buttons/CFButton';
import { CFRole } from 'domain/general.types';
import { AssistantMessage, QuestionType } from 'services/assistant/assistant.types';
import { useMessagesContext } from 'views/assistant/context/useMessagesContext';

import './substitution-request-message.scss';

interface Props {
  message: AssistantMessage;
  //onOptionSelected: (key: string, option: string) => void;
}

const SubstitutionRequestMessage = ({ message }: Props) => {
  const { disambiguate } = useMessagesContext();
  const [selected, setSelected] = useState('');

  if (!message.substitutions) {
    return <div></div>;
  }

  const handleOptionSelected = useCallback((option: string) => {
    if (!message.substitutions) {
      return;
    }

    setSelected(option);
    disambiguate(QuestionType.Analytics, message, message.substitutions[0].key, option);
  }, []);

  const currentSubstitution = message.substitutions[0];

  return (
    <div className="substitution-request">
      <div>
        I couldn&apos;t find data for <span className="replace-key">{currentSubstitution.key}</span> in the system. Can
        you choose one of the following options?
      </div>

      <div className="substitution-request__options">
        {currentSubstitution.options.map((option) => (
          <CFButton
            key={option}
            value={option}
            onClick={() => handleOptionSelected(option)}
            role={selected === '' ? CFRole.Primary : selected === option ? CFRole.Primary : CFRole.Neutral}
            disabled={selected !== ''}
          />
        ))}
      </div>
    </div>
  );
};

export default SubstitutionRequestMessage;
