import React, { FC } from 'react';

import { Trait } from 'domain/traits.types';

import CFTrashButton from 'components/buttons/CFTrashButton';
import CFTraitItem from 'connected-components/traits/CFTraitItem';

import './traits-list-item.scss';
import CFWindowsInfo from 'connected-components/traits/CFWindowsInfo';

interface ItraitListItem {
  trait: Trait;
  windows?: number[];
  onDelete: (trait: Trait) => void;
}

const TraitListItem: FC<ItraitListItem> = ({ trait, windows, onDelete }) => {
  const { description } = trait.meta;

  return (
    <div className="trait-list-item">
      <div className="trait-list-item-element">
        <CFTrashButton onClick={() => onDelete(trait)} />
      </div>
      <div className="trait-list-item-element">
        <CFTraitItem addr={trait.addr} />
      </div>

      <div className="trait-list-item-element">
        <CFWindowsInfo ptr={trait.addr.ptr} windows={windows} />
      </div>

      <div className="trait-list-item-element">{description}</div>
    </div>
  );
};

export default TraitListItem;
