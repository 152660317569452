import React, { useCallback, useEffect, useState } from 'react';

import { useServicesContext } from 'hooks/useServicesContext';

import { CFRole } from 'domain/general.types';
import { ModelId, ModelMetrics, RecommenderMetrics } from 'domain/model.types';

import { AlgorithmClass } from 'services/intervention/intervention.types';
import { getMetrics } from 'services/model/model.repo';

import useURLModel from 'views/model/hooks/useURLModel';
import InterventionBanditMetrics from 'views/intervention/interventions/monitoring/metrics/bandit-metrics';

import CFButton from 'components/buttons/CFButton';
import CFSpinner from 'components/CFSpinner';
import { ToastType } from 'components/CFToast/types';
import SurvivalCurve from './components/SurvivalCurve';
import LifetimeChart from './components/LifetimeChart';
import PredictedVSObservedLifetime from './components/PredictedVSObservedLifetime';
import ScoreTimeSerie from './components/ScoreTimeSerie';
import RecommenderMetricsChart from './components/RecommenderMetrics';

import { Tabs } from '../../tabs';
import ModelDetailLayout from '../layout';

import colors from 'common.scss';

import useToast from 'hooks/useToast';

import { cindexIPCWText, cindexText } from './constants';

import './model-metrics.scss';

const buttonTabs = [
  { label: 'Scores', value: 'scores' },
  { label: 'Plot', value: 'plot' },
] as const;

type ButtonTabs = (typeof buttonTabs)[number]['value'];

const ModelMetricsViewer = () => {
  const model = useURLModel();

  const { addToast } = useToast();
  const [currentButtonTab, setCurrentButtonTab] = useState<ButtonTabs>(buttonTabs[0].value);
  const [isLoading, setIsLoading] = useState(true);
  const [modelMetrics, setModelMetrics] = useState<ModelMetrics>();

  const { interventionService, traitSessionService } = useServicesContext();

  const handleCurrentButton = useCallback(
    (buttonTab: ButtonTabs) => () => {
      setCurrentButtonTab(buttonTab);
    },
    []
  );

  useEffect(() => {
    (async () => {
      if (!model) {
        return;
      }

      await fetchMetricsData(model.definition.id);

      setIsLoading(false);
    })();
  }, [model]);

  const fetchMetricsData = async (id: ModelId) => {
    if (!model) {
      return;
    }

    if (!model.definition.id || model.definition?.algo_spec?.class_name === AlgorithmClass.Bandit) {
      return;
    }

    let modelMetrics;

    try {
      modelMetrics = await getMetrics(id);
      setModelMetrics(modelMetrics);
    } catch {
      addToast('Data not ready', ToastType.INFO);

      return;
    }
  };

  return (
    <ModelDetailLayout className="model-metrics" tab={Tabs.Metrics}>
      {isLoading && <CFSpinner size={70} color={colors.cfCyan} stroke={4} />}

      {!isLoading && model?.definition.algo_spec.class_name === AlgorithmClass.Recommender && (
        <RecommenderMetricsChart metrics={modelMetrics?.recom as RecommenderMetrics} />
      )}

      {!isLoading && model?.definition.algo_spec.class_name === AlgorithmClass.Censoring && (
        <div className="model-metrics-container">
          <div className="model-metrics-controls">
            {buttonTabs.map(({ label, value }) => (
              <CFButton
                key={value}
                value={label}
                onClick={handleCurrentButton(value)}
                role={currentButtonTab === value ? CFRole.Cyan : CFRole.Secondary}
              />
            ))}
          </div>

          {currentButtonTab === 'scores' && (
            <div className="model-metrics-scores">
              <ScoreTimeSerie modelMetrics={modelMetrics} description={cindexText} type="cindex" />
              <ScoreTimeSerie modelMetrics={modelMetrics} description={cindexIPCWText} type="cindex_ipcw" />
            </div>
          )}

          {modelMetrics && currentButtonTab === 'plot' && (
            <div className="model-metrics-plots">
              <PredictedVSObservedLifetime className="lifetime" modelMetrics={modelMetrics} />
              <LifetimeChart className="lifetimeNext" />
              <SurvivalCurve className="survival" />
            </div>
          )}
        </div>
      )}
      {model?.invId !== -1 &&
        (model?.definition.algo_spec.class_name === AlgorithmClass.Bandit ||
          model?.definition.algo_spec.class_name === AlgorithmClass.RestlessBandit) && (
          <div className="intervention-metrics">
            <InterventionBanditMetrics
              interventionService={interventionService}
              traitService={traitSessionService}
              id={model.invId}
            />
          </div>
        )}
    </ModelDetailLayout>
  );
};

export default ModelMetricsViewer;
