export type EventCallback<T> = (value: T) => void;

export default class EventDispatcher<T, E extends keyof any> {
  private callbacks: Partial<Record<E, EventCallback<T>[]>> = {};

  notify = (type: E, view: T) => {
    const callbacksForType = this.callbacks[type];

    if (callbacksForType === undefined) {
      return;
    }

    callbacksForType.forEach((cb) => cb(view));
  };

  subscribe = (type: E, callback: EventCallback<T>) => {
    let callbacksForType = this.callbacks[type];

    if (!callbacksForType) {
      callbacksForType = [];
      this.callbacks[type] = callbacksForType;
    }

    callbacksForType.push(callback);
  };

  unsubscribe = (type: E, callback: EventCallback<T>) => {
    const callbacksForType = this.callbacks[type];

    if (!callbacksForType) {
      return;
    }

    const index = callbacksForType.findIndex((cb) => cb === callback);
    callbacksForType.splice(index, 1);
  };
}
