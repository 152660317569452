import { ColAddr } from 'domain/traits.types';
import { useEffect } from 'react';
import { useAnalyticsContext } from '../context/useAnalyticsContext';

import usersRepo from 'services/admin/users/users.repo'; // should avoid reference repo directly?
import { useServicesContext } from 'hooks/useServicesContext';
import { TraitAction } from './useInitializedTraits';
import { AggLevel } from 'domain/stats.types';
import { Ptr } from 'services/cohort/cohort.types.api';

const useTraitsUpdater = () => {
  const { module, subject, traitsState, dispatchTraitAction, explorationMode } = useAnalyticsContext();
  const { traitSessionService: traitService } = useServicesContext();

  useEffect(() => {
    (async () => {
      const availableTraits = (await usersRepo.getGDTSettings(subject, module)).filter(
        (coldAddr) => traitService.getTraitFromAddr(coldAddr) !== undefined
      );

      const ptrs = availableTraits.map((colAddr: ColAddr) => colAddr.ptr);

      dispatchTraitAction({ action: TraitAction.LOAD, ptrs });
    })();
  }, [module, subject]);

  useEffect(() => {
    (async () => {
      if (explorationMode) {
        return;
      }

      const ptrs = Object.keys(traitsState.traits)
        .map((key) => traitsState.traits[key as AggLevel])
        .flat()
        .filter((ptr) => ptr !== undefined);

      const addrs = ptrs.map((ptr) => traitService.getTraitDefinition(ptr as Ptr).addr);

      await usersRepo.updateGDTSettings(subject, module, addrs as ColAddr[]);
    })();
  }, [traitsState, explorationMode]);
};

export default useTraitsUpdater;
