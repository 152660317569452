import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import moment from 'moment';
import { DateRange } from 'components/DateTime/DatetimeRangePicker/types';
import { StartEndPair } from 'services/intervention/intervention.types';
import { TimeRFC3999 } from 'domain/general.types';
import { AggLevel } from 'domain/stats.types';
import { Schedule } from 'services/scheduling/schedulting.types.api';

dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(timezone);
dayjs.extend(minMax);

export enum Granularity {
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export const roundToMinutes = (date: Date): Date => {
  const noSeconds = new Date(date.setSeconds(0));
  return new Date(new Date(noSeconds).setMilliseconds(0));
};

export const today = (isStartFromMidnight = false): Date => {
  const now = new Date();

  if (isStartFromMidnight) {
    // round to start time of the day
    now.setUTCHours(24, 0, 0, 0);
  }

  return now;
};

export const daysAgo = (days: number): TimeRFC3999 => {
  return toString(dayjs().subtract(days, 'days').toDate());
};

export const oneWeekAgo = (isStartFromMidnight = false): Date => {
  const now = new Date();
  if (isStartFromMidnight) {
    // round to start time of the day
    now.setUTCHours(24, 0, 0, 0);
  }
  return new Date(now.setDate(now.getDate() - 7));
};

export const nineMonthsAgo = (): string => {
  const now = new Date();

  const nineMonthsBack = moment(now).subtract(9, 'month').toDate();

  return nineMonthsBack.toISOString().slice(0, -5) + 'Z';
};

export const getEndDate = (startDate?: string) => {
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));

  if (!startDate) {
    return toString(yesterday);
  }

  const createdAt = new Date(startDate);

  if (createdAt > yesterday) {
    return toString(new Date());
  }

  return toString(yesterday);
};

export const nanosecondsFromIsoString = (date: string): number => {
  return new Date(date).getTime() * 10 ** 6;
};

export const pruneHours = (date: Date): string => {
  return dayjs(date).format('YYYY-MM-DDT00:00:00Z');
};

export const pruneHoursNoTimezone = (date: Date): string => {
  return dayjs(date).format('YYYY-MM-DDT00:00:00') + 'Z';
};

export const toString = (date: Date): string => {
  return date.toISOString().slice(0, -5) + 'Z';
};

export const overlaps = (slots: StartEndPair[]): boolean => {
  const mappedSlots: Record<number, number> = {};

  const populateSlot = (start: Date, end: Date, duration: number): number[] => {
    const ticks = [];
    let counter = 0;
    let currentTickTimestmap = start.getTime() + (duration / 10 ** 6) * counter;

    while (currentTickTimestmap < end.getTime()) {
      currentTickTimestmap = start.getTime() + (duration / 10 ** 6) * counter++;

      ticks.push(currentTickTimestmap);
    }

    return ticks;
  };

  for (const slot of slots) {
    const ticks = populateSlot(new Date(slot.start), new Date(slot.end as string), slot.dur);

    for (const tick of ticks) {
      if (!mappedSlots[tick]) {
        mappedSlots[tick] = 1;
      } else {
        return true;
      }
    }
  }

  return false;
};

export const isBetween = (day: Date, minDate: Date, maxDate: Date): boolean => {
  return dayjs(day).isAfter(minDate) && dayjs(day).isBefore(maxDate);
};

export const getDaysInMonth = (date: Date) => {
  const startWeek = dayjs(date).startOf('month').startOf('week');
  const endWeek = dayjs(date).endOf('month').endOf('week');
  const days = [];

  let curr = startWeek;
  while (curr.isBefore(endWeek)) {
    days.push(curr.toDate());
    curr = curr.add(1, 'day');
  }
  return days;
};

export const isStartOfRange = ({ startDate }: DateRange, day: Date) =>
  (startDate && dayjs(day).isSame(startDate, 'day')) as boolean;

export const isEndOfRange = ({ endDate }: DateRange, day: Date) =>
  (endDate && dayjs(day).isSame(endDate, 'day')) as boolean;

export const inDateRange = ({ startDate, endDate }: DateRange, day: Date): boolean => {
  const dayjsDay = dayjs(day);

  return (
    (startDate && endDate && isBetween(day, startDate, endDate)) ||
    dayjs(startDate).isSame(dayjsDay, 'day') ||
    (!!endDate && dayjs(endDate).isSame(dayjsDay, 'day'))
  );
};

export const isRangeSameDay = ({ startDate, endDate }: DateRange) => {
  if (startDate && endDate) {
    return dayjs(startDate).isSame(endDate, 'day');
  }
  return false;
};

export const displayDate = (
  date: Date | null | undefined,
  time: string | undefined,
  showTime: boolean,
  isTimezone: boolean,
  timezone: string,
  format?: string
) => {
  const dateStr = dayjs(date).format('YYYY-MM-DD');
  const dateTimeStr = `${dateStr}T${time}`;
  const dateTime = showTime ? dayjs.tz(dateTimeStr, timezone) : dayjs(dateTimeStr);
  const dateFormat = format ?? `YYYY-MM-DD${showTime ? ` HH:mm ${isTimezone ? '(UTCZ)' : ''}` : ''}`;

  return dateTime.format(dateFormat);
};

export interface WeekDay {
  month: number;
  dayOfMonth: number;
}

export const getDaysForWeek = (week: number, year: number): WeekDay[] => {
  const weekDays: WeekDay[] = [];
  const baseDate = `${year}-01-01`;
  const dateForThisWeek = dayjs(baseDate).week(week);

  const dateAtWeek = dateForThisWeek.date();
  const nextMonth = dateForThisWeek.clone().add(1, 'month');

  const lastDayOfThisMonth = parseInt(dayjs(dateForThisWeek).endOf('month').format('DD'));

  const daysLeftThisMonth = Math.min(lastDayOfThisMonth - dateAtWeek, 6);

  for (let i = dateAtWeek; i <= dateAtWeek + daysLeftThisMonth; i++) {
    weekDays.push({ dayOfMonth: i, month: dateForThisWeek.month() });
  }

  for (let i = 1; i < 7 - daysLeftThisMonth; i++) {
    weekDays.push({ dayOfMonth: i, month: nextMonth.month() });
  }

  return weekDays;
};

export const getEdgeDateRanges = (schedule: Schedule): { startDate: Dayjs; endDate: Dayjs } => {
  if (schedule.definition.recurring) {
    const { pairs = [] } = schedule.definition.recurring;

    if (pairs.length === 0) {
      throw new Error('pairs length cannot be 0');
    }

    let startDate = dayjs(pairs[0].start);
    let endDate = dayjs(pairs[0].end);

    for (let i = 1; i < pairs.length; i++) {
      const start = dayjs(pairs[i].start);
      if (!startDate || startDate > start) {
        startDate = start;
      }

      const end = dayjs(pairs[i].end);
      if (!endDate || endDate < end) {
        endDate = end;
      }
    }

    return {
      startDate,
      endDate,
    };
  }

  const { pts = [] } = schedule.definition.time_points || {};

  return pts.reduce<{ startDate: Dayjs; endDate: Dayjs }>(
    (acc, date) => {
      const cur = dayjs(date);

      if (cur < acc.startDate) {
        acc.startDate = cur;
      }

      if (cur > acc.endDate) {
        acc.endDate = cur;
      }

      return acc;
    },
    { startDate: dayjs(pts[0]), endDate: dayjs(pts[0]) }
  );
};

export const getDate = (date: string) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const getTime = (date: string) => {
  return dayjs(date).format('HH:mm:ss');
};

export const getRecurringDatesForMonth = (pairs: StartEndPair[], month: Date) => {
  return pairs.map(({ start, dur, end }) => {
    const ticks: Date[] = [];
    const startDate = new Date(start);
    let currentTickTimestmap = startDate.getTime();
    let endOfMonth = dayjs(month).endOf('month').toDate().getTime();

    if (end) {
      const endDate = new Date(end).getTime();

      if (endDate < endOfMonth) {
        endOfMonth = endDate;
      }
    }

    let safetyCounter = 0;
    while (currentTickTimestmap <= endOfMonth) {
      ticks.push(new Date(currentTickTimestmap));

      currentTickTimestmap += dur / 10 ** 6;

      if (safetyCounter++ > 1000) break;
    }

    return ticks;
  });
};

export const timeConverter: Record<string, (value: number) => number> = {
  [Granularity.Minute]: (value: number) => value * 60 * 10 ** 9,
  [Granularity.Hour]: (value: number) => value * 3600 * 10 ** 9,
  [Granularity.Day]: (value: number) => value * 24 * 3600 * 10 ** 9,
  [Granularity.Week]: (value: number) => value * 7 * 24 * 3600 * 10 ** 9,
  [Granularity.Month]: (value: number) => value * 30 * 24 * 3600 * 10 ** 9,
};

export const timeConverterFromNanos: Record<string, (value: number) => number> = {
  [Granularity.Minute]: (value: number) => value / (60 * 10 ** 9),
  [Granularity.Hour]: (value: number) => value / (3600 * 10 ** 9),
  [Granularity.Day]: (value: number) => value / (24 * 3600 * 10 ** 9),
  [Granularity.Week]: (value: number) => value / (7 * 24 * 3600 * 10 ** 9),
  [Granularity.Month]: (value: number) => value / (30 * 24 * 3600 * 10 ** 9),
};

export const getDurationFromNanos = (nanos: number, granularity: Granularity) => {
  return timeConverterFromNanos[granularity](nanos);
};

export const getCurrentGmtTz = () => {
  const now = dayjs.tz();
  const gmtOffsetMinutes = now.utcOffset();
  const gmtOffsetHours = Math.floor(Math.abs(gmtOffsetMinutes) / 60);
  const gmtOffsetSign = gmtOffsetMinutes < 0 ? '-' : '+';

  return `GMT${gmtOffsetSign}${gmtOffsetHours}`;
};

export const roundEdges = (
  startTime: TimeRFC3999,
  endTime: TimeRFC3999,
  aggLevel: AggLevel
): [TimeRFC3999, TimeRFC3999] => {
  const roundedStart = dayjs.utc(startTime).startOf(aggLevel as OpUnitType);
  const roundedEnd = dayjs.utc(endTime).endOf(aggLevel as OpUnitType);

  return [toString(roundedStart.toDate()), toString(roundedEnd.toDate())];
};

export const belongToSameGap = (date: TimeRFC3999, aggLevel: AggLevel) => {
  const targetDate = dayjs(date);
  const now = dayjs();

  return now.isSame(targetDate, aggLevel as OpUnitType);
};

export const isSameDayAsToday = (date: string | Date | dayjs.Dayjs): boolean => {
  const paramDate = dayjs(date);
  const today = dayjs();

  return paramDate.isSame(today, 'day');
};
