import CFButton from 'components/buttons/CFButton';
import CFInput from 'components/CFInput';
import React, { useState } from 'react';
import useToast from 'hooks/useToast';

import { CFRole } from 'domain/general.types';

import { RetrievedChunkAPI } from 'services/upload/upload.types';
import { search } from 'services/upload/upload.service';

import CFModal from 'components/CFModal';
import { ToastType } from 'components/CFToast/types';

import ChunkItem from 'connected-components/ChunkItem';

const SearchEmbeddings = () => {
  const { addToast } = useToast();

  const [searching, setSearching] = useState(false);
  const [searchResponse, setSearchResponse] = useState<RetrievedChunkAPI[] | undefined>();

  const handleSearchSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const searchValue = formData.get('search') as string;

    setSearching(true);
    try {
      const searchResponse = await search(searchValue);
      setSearchResponse(searchResponse);
    } catch (e) {
      addToast('' + e, ToastType.ERROR);
    } finally {
      setSearching(false);
    }
  };

  return (
    <>
      {searchResponse && (
        <CFModal onClose={() => setSearchResponse(undefined)} className="search-info">
          <div className="search-info-chunks">
            {searchResponse.map((response, i) => (
              <ChunkItem key={i} chunk={response} />
            ))}
          </div>
        </CFModal>
      )}

      <form onSubmit={handleSearchSubmit}>
        <div className="search-form">
          <CFInput name="search" />

          <CFButton
            type="submit"
            role={CFRole.Primary}
            value={`Search`}
            onClick={() => ({})}
            disabled={searching}
            //isLoading={searching}
          />
        </div>
      </form>
    </>
  );
};

export default SearchEmbeddings;
