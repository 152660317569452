import React, { useMemo } from 'react';

import MarkovSubjectViewer from 'views/intervention/interventions/NewIntervention/states/MarkovSubjectViewer';
import { InterventionViewExtended } from 'services/intervention/intervention.types';

import CFTitledComponent from 'components/CFTitledComponent';
import Box from 'components/Box';
import CFTitledSection from 'components/CFTitledSection';

import './markov.scss';

interface Props {
  intervention: InterventionViewExtended;
}

const MarkovStatesDefinition = ({ intervention }: Props) => {
  const markovProcessID = useMemo(() => {
    return intervention.intervention.algo_policy.spec?.markov?.id || '';
  }, [intervention]);

  const nudgeNames = useMemo(() => {
    return Object.keys(intervention.intervention.nudge_policy.group_allocation);
  }, []);

  // edits are already computed at backend so no need to send then back
  return (
    <CFTitledSection title="Markov states" className={`metrics-definition metrics markov-monitoring`}>
      <div className="markov-monitoring__params">
        <CFTitledComponent title={'Budget'}>
          <Box className="field">{intervention.intervention.algo_policy.spec?.markov?.budget}</Box>
        </CFTitledComponent>

        <CFTitledComponent title={'Population blend'}>
          <Box className="field">{intervention.intervention.algo_policy.spec?.markov?.population_blend}</Box>
        </CFTitledComponent>

        <CFTitledComponent title={'Gap'}>
          <Box className="field">{intervention.intervention.algo_policy.spec?.markov?.gap}</Box>
        </CFTitledComponent>

        <CFTitledComponent title={'Offset'}>
          <Box className="field">{intervention.intervention.algo_policy.spec?.markov?.offset}</Box>
        </CFTitledComponent>
      </div>

      <MarkovSubjectViewer processId={markovProcessID} updates={{}} selectedNudgeNames={nudgeNames} />
    </CFTitledSection>
  );
};

export default MarkovStatesDefinition;
