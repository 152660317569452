import React from 'react';
import dayjs from 'dayjs';

import { AppModel } from 'domain/model.types';

import { runStatusClassMap } from 'views/model';

import CFTooltip, { CFTooltipPositions } from 'components/CFTooltip';

import './model-runs-summary.scss';

interface Props {
  model: AppModel;
}

const ModelRunsSummary = ({ model }: Props) => {
  return (
    <div className="model-runs-summary">
      {model.schedule?.slots &&
        Object.entries(model.schedule.slots)
          .slice(-5)
          .map(([date, slot], idx) => (
            <CFTooltip
              key={idx}
              description={dayjs(date).tz().format('DD-MM-YYYY HH:mm:ss z')}
              position={CFTooltipPositions.Right}
              anchor={``}
            >
              <div className={runStatusClassMap[slot.status]}></div>
            </CFTooltip>
          ))}
    </div>
  );
};

export default ModelRunsSummary;
