import React from 'react';

import { Model, PtrWithWindows } from 'domain/model.types';
import MonitoringSection from '../components/monitoringSection';
import ModelCohort from '../components/cohort';
import Cohort from 'services/cohort/domain/Cohort';
import ModelTarget from '../components/target';
import Schedule from 'connected-components/schedule';
import ModelRuns from '../components/runs';
import { AlgorithmName } from 'services/intervention/intervention.types';
import CFTraitItem from 'connected-components/traits/CFTraitItem';
import CFWindowsInfo from 'connected-components/traits/CFWindowsInfo';
import { getTraitNameFromAddr } from 'services/traits/helpers.traits';
import CFTitledComponent from 'components/CFTitledComponent';

interface Props {
  model: Model;
}

import './model-censoring-details.scss';

const CensoringDynamicFeatures = ({ ptrWindows }: { ptrWindows: PtrWithWindows[] }) => {
  return (
    <CFTitledComponent title="Dynamic features">
      {ptrWindows.map((ptrWindow) => {
        return (
          <div key={ptrWindow.ptr} className="trait-with-info">
            <CFTraitItem key={ptrWindow.ptr} addr={ptrWindow.ptr} />
            <CFWindowsInfo ptr={ptrWindow.ptr} windows={ptrWindow.windows} />
          </div>
        );
      })}
    </CFTitledComponent>
  );
};

const RDSMDynamicFeatures = ({ model }: { model: Model }) => {
  if (!model.definition.data_defn.dynamic_features) {
    return null;
  }

  return (
    <CFTitledComponent title="Dynamic features">
      {model.definition.data_defn.dynamic_features?.map((feat) => {
        const censoring = model.definition.data_defn.censoring;
        const transformations = censoring?.transformations;
        const transformation = transformations ? transformations[feat.ptr] : undefined;

        return (
          <div key={feat.ptr} className="trait-with-info">
            <CFTraitItem key={feat.ptr} addr={feat.ptr} />
            <span> ({(transformation || '').toUpperCase()}) </span>
          </div>
        );
      })}
    </CFTitledComponent>
  );
};

const ModelCensoringDetails = ({ model }: Props) => {
  const algorithm = model.definition.algo_spec.algo_name;
  const staticFeatures = model.definition.data_defn.static_features || [];

  return (
    <>
      <MonitoringSection title="Cohort">
        <ModelCohort
          model={model}
          cohort={new Cohort(model.cohort)}
          splitRatio={model.definition.population_policy.split_ratio}
        />
      </MonitoringSection>

      <MonitoringSection title="Features">
        {algorithm !== AlgorithmName.DeepRecurrentSurvivalMachines && (
          <CensoringDynamicFeatures ptrWindows={model.definition.data_defn.censoring?.dynamic_features || []} />
        )}

        {algorithm === AlgorithmName.DeepRecurrentSurvivalMachines && <RDSMDynamicFeatures model={model} />}

        {staticFeatures.length !== 0 && (
          <CFTitledComponent title="Static features">
            <div className="selected-items">
              {staticFeatures.map((addr) => (
                <div className="trait-with-info" key={getTraitNameFromAddr(addr)}>
                  <CFTraitItem addr={addr} />
                </div>
              ))}
            </div>
          </CFTitledComponent>
        )}
      </MonitoringSection>

      <MonitoringSection title="Target">
        <ModelTarget model={model} />
      </MonitoringSection>

      <MonitoringSection title="Runs">
        <ModelRuns model={model} />
      </MonitoringSection>

      <Schedule id={model.schedule.id} label="model" />
    </>
  );
};

export default ModelCensoringDetails;
