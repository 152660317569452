import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as LlmIcon } from 'views/assistant/images/coloredchat.svg';

import { Trait, TraitCategory, TraitSubject } from 'domain/traits.types';
import { CFRole, Modules } from 'domain/general.types';

import sessionService, { getCurrentProject } from 'services/session/session.service';
import { createTraitCode, getDisplayName, subjectMatches } from 'services/traits/helpers.traits';
import { TraitExploreResp } from 'services/assistant/assistant.types';
import { AuthAction, isAllowedTo } from 'services/authorization.service';

import CFSelect, { Option } from 'components/CFSelect';
import TraitItem from 'components/CFSelect/common/TraitItem';
import CFButton from 'components/buttons/CFButton';
import CFSubmitTextarea from 'components/textarea/CFSubmitTextarea';
import { ToastType } from 'components/CFToast/types';

import { useAnalyticsContext } from '../../context/useAnalyticsContext';
import { selectCohort } from '../../services/cohort';
import { useServicesContext } from 'hooks/useServicesContext';

import { useToast } from 'hooks';

import { SESSION_COHORT_KEY } from '../../hooks/useInitializedCohorts';
import { SESSION_SUBJECT_KEY } from '../../hooks/useInitializedSubject';
import { SESSION_MODULE_KEY } from '../../hooks/useInitializedModule';
import { TraitAction } from '../../hooks/useInitializedTraits';

import './filter-controls.scss';

const FilterControls = () => {
  const { cohortService, traitSessionService: traitService, assistantService } = useServicesContext();
  const textareaRef = React.createRef<HTMLTextAreaElement>();
  const { addToast } = useToast();

  const {
    subject,
    module,
    aggLevel,
    setModule,
    setSubject,
    dispatchTraitAction,
    traitsState,
    setSelectedCohortIDs,
    selectedCohortIDs,
    availableCohorts,
    explorationMode,
    setExplorationMode,
    setExplorationSearch,
  } = useAnalyticsContext();
  const project = getCurrentProject();

  const [traitSearch, setTraitSearch] = useState('');
  const [cohortSearch, setCohortSearch] = useState('');
  const [traits, setTraits] = useState<Trait[]>([]);

  const [loadingExplore, setLoadingExplore] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!module || !subject) {
        return;
      }
      // TODO: mover a context??

      const allTraits = await traitService.getTraits({
        subject,
        module: module as Modules,
        aggLevel,
        category: TraitCategory.Grouped,
        visibility: false,
      });
      setTraits(allTraits);
    })();
  }, [subject, module, aggLevel]);

  const selectableCohorts = (availableCohorts ?? []).map((item) => ({
    label: `${item.name} (${item.size})`,
    value: item.id.toString(),
  }));

  const selectableSubjects = (project?.subjects || []).map((subject_type) => ({
    label: subject_type,
    value: subject_type,
  }));

  const traitOptions = useMemo(() => {
    return traits
      .map((trait) => {
        return {
          label: getDisplayName(trait),
          value: createTraitCode(trait),
          meta: {
            trait: trait,
            simple: true,
          },
        };
      })
      .filter((option) => {
        return !traitSearch.trim() || option.label.toLowerCase().includes(traitSearch.toLowerCase());
      });
  }, [traits, traitSearch]);

  const handleSelectCohort = useCallback(
    (item: Option) => {
      setSelectedCohortIDs((prev) => {
        const newIDs = selectCohort(
          parseInt(item.value),
          prev.map((item) => parseInt(item)),
          availableCohorts ?? []
        );

        return newIDs.map((item) => `${item}`);
      });
    },
    [availableCohorts]
  );

  const handleModuleChange = useCallback((item: Option) => {
    setModule(item.value);
  }, []);

  const handleSelectSubject = useCallback((item: Option) => {
    setSubject(item.value as TraitSubject);
  }, []);

  const traitValues = useMemo(() => {
    return (traitsState.traits[aggLevel] || [])
      .filter((ptr) => {
        return subjectMatches(ptr, subject);
      })
      .map((traitCode) => {
        const trait = traitService.getTraitDefinition(traitCode);
        if (!trait) {
          return { label: '', value: '' };
        }

        return {
          label: getDisplayName(trait),
          value: traitCode,
        };
      });
  }, [traitsState, aggLevel, traitService]);

  const handleAskAssistant = useCallback(async () => {
    let search;

    if (!textareaRef.current) {
      return;
    }

    setExplorationMode(true);
    setLoadingExplore(true);

    try {
      search = await assistantService.searchTraits(textareaRef.current.value);
    } catch {
      addToast('Error searching traits', ToastType.ERROR);
      setExplorationMode(false);
      setLoadingExplore(false);
      return;
    }

    setLoadingExplore(false);

    dispatchTraitAction({ action: TraitAction.LOAD, ptrs: (search as TraitExploreResp).traits });
    setSubject(search.subject);
    setModule(search.module);
    setSelectedCohortIDs(search.cohorts.map((briefCohort) => `${briefCohort.id}`));

    setExplorationSearch(search);
  }, [textareaRef, aggLevel]);

  const handleDiscardExploring = useCallback(() => {
    const sessionCohortIDs = sessionService.getCustomValue(SESSION_COHORT_KEY);
    const sessionSubject = sessionService.getCustomValue(SESSION_SUBJECT_KEY);
    const sessionModule = sessionService.getCustomValue(SESSION_MODULE_KEY);

    setSelectedCohortIDs(sessionCohortIDs);
    setSubject(sessionSubject);
    setModule(sessionModule);

    setExplorationMode(false);
    setLoadingExplore(false);
  }, []);

  const handleAcceptExploring = useCallback(() => {
    setExplorationMode(false);
  }, []);

  const handleSelectTrait = useCallback(
    (option: Option) => {
      const previousSelected = traitValues.find((selectedOption) => option.value === selectedOption.value);

      const trait = traitService.getTraitDefinition(option.value);
      dispatchTraitAction({ action: previousSelected ? TraitAction.REMOVE : TraitAction.ADD, ptr: trait.addr.ptr });
    },
    [traitValues]
  );

  return (
    <div className={classNames('control-container', { 'exploration-mode': explorationMode })}>
      {explorationMode && <LlmIcon width={'25px'} className="control-container__llm-icon" />}
      {isAllowedTo(AuthAction.SeeAssistant) && (
        <div className="control-container__assistant">
          <CFSubmitTextarea
            ref={textareaRef}
            onSubmit={handleAskAssistant}
            placeholder="What do you want to explore?"
          />
        </div>
      )}

      <div className="control-container__form">
        <div className="form-group">
          <label>Subject</label>
          <CFSelect
            value={{
              label: subject,
              value: subject,
            }}
            options={selectableSubjects}
            onSelected={handleSelectSubject}
            disabled={explorationMode}
          />
        </div>
        <div className="form-group">
          <label>Module</label>
          <CFSelect
            options={project?.modules?.map((module) => ({ label: module, value: module })) || []}
            value={{ label: module, value: module }}
            onSelected={handleModuleChange}
            disabled={explorationMode}
          />
        </div>
        <div className="form-group">
          <label>Cohort</label>
          <CFSelect
            options={selectableCohorts.sort().filter((option) => {
              return !cohortSearch.trim() || option.label.toLowerCase().includes(cohortSearch.toLowerCase());
            })}
            value={selectedCohortIDs.map((cid) => ({
              label: cohortService.getCohort(cid)?.name || 'unknown',
              value: cid,
            }))}
            isMulti
            onSelected={handleSelectCohort}
            searchable
            onSearch={setCohortSearch}
            disabled={loadingExplore}
          />
        </div>

        <div className="form-group">
          <label>Trait</label>
          <CFSelect
            options={traitOptions}
            value={traitValues}
            onSelected={handleSelectTrait}
            isMulti
            searchable
            onSearch={setTraitSearch}
            Item={TraitItem}
            disabled={loadingExplore}
          />
        </div>
      </div>

      {explorationMode && (
        <div className="control-container__actions">
          <span> Do you want to save this configuration?</span>

          <CFButton value={'Save'} onClick={handleAcceptExploring} isLoading={loadingExplore} role={CFRole.Primary} />
          <CFButton value={'Discard'} onClick={handleDiscardExploring} isLoading={loadingExplore} />
        </div>
      )}
    </div>
  );
};

export default FilterControls;
