import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { getMetric } from 'services/intervention/intervention.repo';
import InterventionService from 'services/intervention/intervention.service';
import TraitService from 'services/traits/traitSession.service';
import { getDisplayName, getIdentifier, getIdentifierFromAddr } from 'services/traits/helpers.traits';
import {
  InterventionId,
  InterventionViewExtended,
  MetricData,
  RestlessMetrics,
} from 'services/intervention/intervention.types';

import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import CFTitledSection, { SectionAction } from 'components/CFTitledSection';
import { DataItem, LineStyle } from 'components/charts/chartCommon';
import CFLineChartWithConfidenceInterval from 'components/charts/CFLineChartWithConfidenceInterval';

import RestlessAdvantage from '../definition/restlessAdvantage';
import NudgeStats from './NudgeStats';
import RestlessGroupProportions from './RestlessGroupProportions';
import RestlessStateProportions from './RestlessStateProportions';

import { getColorForGroup } from './helpers';

import { CFRoutes } from 'routes';

import useCFNavigation from 'hooks/useCFNavigation';

import { advantageScoring, nudgeStats } from './constants';

import './metrics.scss';

const ALL_GROUPS = 'all_groups';

interface Props {
  interventionService: InterventionService;
  traitService: TraitService;
  id?: InterventionId;
}

const InterventionRestlessBanditMetrics = ({ interventionService, traitService, id }: Props) => {
  const navigate = useCFNavigation();

  const [metrics, setMetrics] = useState<MetricData>();
  const [isWithConfidenceLoading, setIsWithConfidenceLoading] = useState<boolean>(true);
  const [intervention, setIntervention] = useState<InterventionViewExtended>();
  const [selectedTraitName, setSelectedTraitName] = useState<string>('');

  const params = useParams();

  const interventionId = useMemo(() => {
    return id || parseInt(params.id as string);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const intv = await interventionService.getView(interventionId);
        setIntervention(intv);
      } catch (err: any) {
        navigate(CFRoutes.intervention);
      }
    })();
  }, [interventionId]);

  useEffect(() => {
    (async () => {
      const metrics = await getMetric(interventionId);
      setMetrics(metrics);

      setIsWithConfidenceLoading(false);
    })();
  }, []);

  const traitNames = useMemo(() => {
    if (!intervention) {
      return [];
    }

    const traitNames = (intervention.intervention.metric_policy.restless as RestlessMetrics).metrics.map((metric) =>
      getIdentifierFromAddr(metric)
    );

    return traitNames;
  }, [intervention]);

  const traits = useMemo(() => {
    if (!intervention) {
      return [];
    }

    const traits = (intervention.intervention.metric_policy.restless as RestlessMetrics).metrics.map((metric) =>
      traitService.getTraitFromAddr(metric)
    );

    return traits.filter((trait) => !!trait);
  }, [intervention]);

  useEffect(() => {
    if (!traitNames) {
      return;
    }

    setSelectedTraitName(traitNames[0]);
  }, [traitNames]);

  const sortByControlGroup = useCallback(
    (groupName: string): number => {
      if (groupName === intervention?.controlGroup) {
        return -1;
      } else {
        return 1;
      }
    },
    [intervention]
  );

  const populateValuesInSlots = (traitName: string, groupName: string) => {
    if (!metrics || !traitName || !metrics.restless) {
      return [];
    }

    if (!metrics.restless.metrics[traitName]) {
      return [];
    }

    if (!intervention) {
      return [];
    }

    const timestamps = Object.keys(intervention.schedule.slots);

    // first approach: brute force. When working, do it in just one loop
    const data = timestamps.map((timestampValue) => {
      const item = metrics.restless.metrics[traitName][groupName].find(
        (timeValuePair) => timeValuePair.t === timestampValue
      );

      const itemStd = metrics.restless.metrics[traitName][groupName].find(
        (timeValuePair) => timeValuePair.t === timestampValue
      );

      let itemData: DataItem = {
        time: timestampValue,
        value: item?.v === undefined ? null : item?.v,
      };

      if (groupName !== ALL_GROUPS && item && itemStd) {
        itemData = {
          ...itemData,
          upper: item.v + itemStd.v,
          lower: item.v - itemStd.v,
        };
      }

      return itemData;
    });

    return data;
  };

  const timeseriesForTrait = useMemo(() => {
    if (!metrics || !selectedTraitName) {
      return [];
    }

    if (!metrics.restless.metrics[selectedTraitName]) {
      return [];
    }

    return Object.keys(metrics.restless.metrics[selectedTraitName])
      .sort((x, y) => y.localeCompare(x))
      .sort(sortByControlGroup)
      .map((groupName, i) => ({
        name: groupName,
        color: getColorForGroup(groupName, i, intervention?.controlGroup === groupName),
        lineStyle: groupName === ALL_GROUPS ? LineStyle.DOTTED : LineStyle.SOLID,
        data: populateValuesInSlots(selectedTraitName, groupName),
      }));
  }, [metrics, selectedTraitName]);

  return (
    <>
      <CFTitledSection title={'Metrics (avg)'} underlined={true}>
        <SectionAction>
          <div className="chart-action">
            <span>Trait</span>
            {traits?.length && (
              <CFSelectLegacy
                options={(traits || []).map((trait) => ({
                  value: getIdentifier(trait),
                  label: getDisplayName(trait, true),
                }))}
                defaultOption={[
                  {
                    value: getIdentifier(traits[0]),
                    label: getDisplayName(traits[0], true),
                  },
                ]}
                onSelected={(item: SelectableItem[]) => setSelectedTraitName(item[0].value)}
              />
            )}
          </div>
        </SectionAction>

        <div className="reward-chart">
          <CFLineChartWithConfidenceInterval
            data={timeseriesForTrait}
            xLabel="Decision point"
            isLoading={isWithConfidenceLoading}
          />
        </div>
      </CFTitledSection>

      <CFTitledSection
        title={'Group proportions'}
        underlined={true}
        description="Fraction of participants assigned to each arm at each decision point"
      >
        <RestlessGroupProportions counts={metrics?.restless.counts || {}} interventionId={interventionId} />
      </CFTitledSection>

      <CFTitledSection
        title={'State proportions'}
        underlined={true}
        description="Fraction of participants in each state at each decision point"
      >
        <RestlessStateProportions counts={metrics?.restless.counts || {}} interventionId={interventionId} />
      </CFTitledSection>

      <CFTitledSection title={'Restless advantage'} description={advantageScoring} underlined={true}>
        <RestlessAdvantage interventionId={interventionId} />
      </CFTitledSection>

      <CFTitledSection title={'Nudge stats'} description={nudgeStats} underlined={true}>
        <NudgeStats id={interventionId} />
      </CFTitledSection>
    </>
  );
};

export default InterventionRestlessBanditMetrics;
