import Schedule from 'connected-components/schedule';
import { Model } from 'domain/model.types';
import React from 'react';
import Cohort from 'services/cohort/domain/Cohort';
import ModelCohort from '../components/cohort';
import ModelFeatures from '../components/features';
import MonitoringSection from '../components/monitoringSection';
import ModelRuns from '../components/runs';
import ModelTarget from '../components/target';

interface Props {
  model: Model;
}

const ModelBanditDefinition = ({ model }: Props) => {
  console.log('model bandit deifnition');
  return (
    <>
      <MonitoringSection title="Cohort">
        <ModelCohort
          model={model}
          cohort={new Cohort(model.cohort)}
          splitRatio={model.definition.population_policy.split_ratio}
        />
      </MonitoringSection>

      <MonitoringSection title="Features">
        <ModelFeatures model={model} />
      </MonitoringSection>

      <MonitoringSection title="Target">
        <ModelTarget model={model} />
      </MonitoringSection>

      <MonitoringSection title="Runs">
        <ModelRuns model={model} />
      </MonitoringSection>

      <Schedule id={model.schedule.id} label="model" />
    </>
  );
};

export default ModelBanditDefinition;
