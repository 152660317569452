import CFService from 'services/cfservice';
import InterventionService, { InterventionEvent } from 'services/intervention/intervention.service';
import ModelService, { ModelEvent } from 'services/model/model.service';
import NudgeService, { NudgeEvent } from 'services/nudging/nudge.service';
import { updateScheduling } from './scheduling.repo';
import { Schedule, ScheduleDefinition } from './schedulting.types.api';

type Callback = (value: Schedule) => void;

const callbacks: Record<number, Callback[]> = {};

export default class SchedulingService extends CFService {
  private interventionService: InterventionService;
  private modelService: ModelService;
  private nudgeService: NudgeService;

  private schedulings: Record<number, Schedule> = {};

  constructor(interventionService: InterventionService, modelService: ModelService, nudgeService: NudgeService) {
    super();

    this.interventionService = interventionService;
    this.modelService = modelService;
    this.nudgeService = nudgeService;

    interventionService.subscribe(InterventionEvent.VIEW, (intervention) => {
      this.schedulings[intervention.schedule.id] = intervention.schedule;
    });

    nudgeService.subscribe(NudgeEvent.VIEW_ADOPTION, (nudgeAdoption) => {
      this.schedulings[nudgeAdoption.schedule.id] = nudgeAdoption.schedule;
    });

    modelService.subscribe(ModelEvent.VIEW, (appModel) => {
      this.schedulings[appModel.schedule.id] = appModel.schedule;
    });
  }

  async init() {
    //
  }

  getById = (id: number) => {
    return this.schedulings[id];
  };

  update = async (id: number, newScheduling: ScheduleDefinition) => {
    const scheduling = this.schedulings[id];

    if (!scheduling) {
      throw new Error('unknown scheduling');
    }

    try {
      await updateScheduling(id, scheduling.definition, newScheduling);

      scheduling.definition = newScheduling;

      this.schedulings[id] = { ...scheduling };

      this.notify(id, this.schedulings[id]);
    } catch (e) {
      console.error(e);
    }
  };

  notify = (key: number, schedule: Schedule) => {
    callbacks[key].forEach((cb) => cb(schedule));
  };

  subscribe = (key: number, callback: Callback) => {
    if (!callbacks[key]) {
      callbacks[key] = [];
    }

    callbacks[key].push(callback);
  };

  unsubscribe = (key: number, callback: Callback) => {
    if (!callbacks[key]) {
      return;
    }

    const index = callbacks[key].findIndex((cb) => cb === callback);
    callbacks[key].splice(index, 1);
  };
}
