import { SubjectId, TimeRFC3999 } from 'domain/general.types';
import { ModelId } from 'domain/model.types';
import { TimeSeriesItem } from 'domain/stats.types';
import { Trait, TraitName } from 'domain/traits.types';
import { getTraitName } from 'services/traits/helpers.traits';
import TraitService from 'services/traits/traitSession.service';

type TimeSeriesStore = Record<TraitName, Record<SubjectId, TimeSeriesItem[]>>;

let timeSeries: TimeSeriesStore = {};

const mergeTimeseries = (
  traits: Trait[],
  subjects: SubjectId[],
  ts1: TimeSeriesStore,
  ts2: TimeSeriesStore
): TimeSeriesStore => {
  const mergedTimeSeries: Record<TraitName, Record<SubjectId, TimeSeriesItem[]>> = {};

  for (const trait of traits) {
    const traitName = getTraitName(trait);
    if (!mergedTimeSeries[traitName]) {
      mergedTimeSeries[traitName] = {};
    }

    subjects.forEach((subject) => {
      if (ts1[traitName]?.[subject]) {
        mergedTimeSeries[traitName][subject] = ts1[traitName][subject];
      } else if (ts2[traitName]?.[subject]) {
        mergedTimeSeries[traitName][subject] = ts2[traitName][subject];
      }
    });
  }

  return mergedTimeSeries;
};

export const getMLTTimeseries = async (
  traitService: TraitService,
  points: TimeRFC3999[],
  modelId: ModelId,
  traits: Trait[],
  subjects: SubjectId[]
) => {
  const partialTimeSeries = await traitService.getMLTTimeseries(points, modelId, traits, subjects /* newSubjects*/);

  timeSeries = mergeTimeseries(traits, subjects, partialTimeSeries, timeSeries);

  return timeSeries;
};
