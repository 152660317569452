import React, { useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import './cf-chip.scss';

interface Props {
  value: string;
  removable?: boolean;
  onRemove?: (value: string) => void;
}

const CFChip = ({ onRemove, value, removable = true }: Props) => {
  const handleRemove = useCallback(() => {
    if (!onRemove) {
      return;
    }

    onRemove(value);
  }, []);

  return (
    <div key={value} className="cf-chip">
      {value}
      {removable && (
        <FontAwesomeIcon
          className="cf-chip__cross"
          onClick={handleRemove}
          icon={icon({ name: 'xmark', style: 'solid' })}
        />
      )}
    </div>
  );
};

export default CFChip;
