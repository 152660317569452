import {
  AppAdoptedNudge,
  CallToAction,
  CFNudgeRepository,
  CreatingNudge,
  Nudge,
  NudgeID,
  NudgeType,
  RenderMethod,
  RenderPage,
} from 'services/nudging/nudge.types';
import { TraitSubject } from 'domain/traits.types';

import { CFAdoptedNudgeRepository } from 'repositories/adopted_nudges';
import { getOrganization } from 'services/session/session.service';
import TraitService from 'services/traits/traitSession.service';
import { PaginatedElement } from 'types';
import CFService from '../cfservice';
import { sanitize } from './templateParsing';
import EventDispatcher, { EventCallback } from 'helpers/EventDispatcher';

export enum NudgeEvent {
  VIEW_ADOPTION,
}

export const NullNudge: Nudge = {
  id: 0,
  pid: 0,
  use: 0,
  created_by: '',
  created_at: 0,
  name: 'control',
  description: '',
  definition: {
    type: NudgeType.Message,
    message: {
      title: 'dummy-title',
      body: 'dummy-body',
      tags: [],
    },
    render_method: RenderMethod.PushNotification,
    cta: CallToAction.AddToCart,
    render_page: RenderPage.None,
    noop: false,
  },
};

export default class NudgeService extends CFService {
  private nudgeRepository: CFNudgeRepository;
  private adoptedNudgeRepository: CFAdoptedNudgeRepository;
  private traitService: TraitService;

  private eventDispatcher = new EventDispatcher<AppAdoptedNudge, NudgeEvent>();

  constructor(
    nudgeRepository: CFNudgeRepository,
    adoptedNudgeRepository: CFAdoptedNudgeRepository,
    traitService: TraitService
  ) {
    super();
    this.nudgeRepository = nudgeRepository;
    this.adoptedNudgeRepository = adoptedNudgeRepository;
    this.traitService = traitService;

    this._name = 'nudgeService';
  }

  init(): void {
    //
  }

  async getAvailableTraits() {
    const EXCEPTION_ORG_ID = '2'; // medtronic labs
    const traits = await this.traitService.getAvailableTraits();

    if (getOrganization() === EXCEPTION_ORG_ID) {
      return traits.filter(
        (trait) => trait.meta.subject === TraitSubject.Chwsite || trait.meta.subject === TraitSubject.User
      );
    }

    return traits.filter((trait) => trait.meta.subject === TraitSubject.User);
  }

  async list(page: number, per_page: number): Promise<PaginatedElement<Nudge>> {
    const nudges = await this.nudgeRepository.get(page, per_page);

    return nudges;
  }

  async create(nudge: CreatingNudge) {
    if (nudge.definition.type === NudgeType.Message) {
      nudge.definition.message.body = sanitize(nudge.definition.message.body);
    }

    await this.nudgeRepository.create(nudge);
  }

  async get(id: NudgeID) {
    const CONTROL_GROUP_ID = 0;

    if (id === CONTROL_GROUP_ID) {
      return NullNudge;
    }

    const nudge = await this.nudgeRepository.getById(id);
    return nudge;
  }

  async remove(nudgeId: number) {
    await this.nudgeRepository.remove(nudgeId);
  }

  async listAdopted(page: number, per_page: number): Promise<PaginatedElement<AppAdoptedNudge>> {
    const adoptedNudges = await this.adoptedNudgeRepository.get(page, per_page);

    adoptedNudges.data.forEach((nudge) => this.notify(NudgeEvent.VIEW_ADOPTION, nudge));

    return adoptedNudges as PaginatedElement<AppAdoptedNudge>;
  }

  notify = (type: NudgeEvent, view: AppAdoptedNudge) => {
    this.eventDispatcher.notify(type, view);
  };

  subscribe = (type: NudgeEvent, callback: EventCallback<AppAdoptedNudge>) => {
    this.eventDispatcher.subscribe(type, callback);
  };

  unsubscribe = (type: NudgeEvent, callback: EventCallback<AppAdoptedNudge>) => {
    this.eventDispatcher.unsubscribe(type, callback);
  };
}
