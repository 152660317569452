import React, { useEffect, useState } from 'react';

import { TraitSubject } from 'domain/traits.types';
import { useSearchParams } from 'react-router-dom';

import sessionService from 'services/session/session.service';
import { URL_PARAMS } from '../constants';

export const SESSION_SUBJECT_KEY = 'subject';

const useInitializedSubject = (): [TraitSubject, React.Dispatch<React.SetStateAction<TraitSubject>>] => {
  const [searchParams] = useSearchParams();

  const defaultSubject = (searchParams.get(URL_PARAMS.Subject) as TraitSubject) || TraitSubject.User;

  const [subject, setSubject] = useState<TraitSubject>(() => {
    const sessionSubject = sessionService.getCustomValue(SESSION_SUBJECT_KEY);

    if (sessionSubject) {
      return sessionSubject as TraitSubject;
    } else {
      return defaultSubject;
    }
  });

  useEffect(() => {
    console.log('[ptrs] useinitialized subjet');
  }, [subject]);

  return [subject, setSubject];
};

export default useInitializedSubject;
