import { TimeRFC3999 } from 'domain/general.types';
import { findDeletedInArray, findNewInArray } from 'helpers/arrays';
import { ScheduleDefinition, ScheduleUpdateReq, StartEndPair } from './schedulting.types.api';

export const transformRecurring = (
  oldScheduling: ScheduleDefinition,
  newScheduling: ScheduleDefinition
): ScheduleUpdateReq => {
  const newPairsCount = (newScheduling.recurring?.pairs.length || 0) - (oldScheduling.recurring?.pairs.length || 0);

  let newPairs: StartEndPair[] = [];

  if (newPairsCount !== 0) {
    newPairs = newScheduling.recurring?.pairs.slice(-newPairsCount) || [];
  }

  const pairsToEdit = newScheduling.recurring?.pairs
    .slice(0, newScheduling.recurring?.pairs.length - newPairsCount)
    .map((item, idx) => ({
      idx,
      delete: false,
      new_end: item.end as TimeRFC3999,
    }));

  return {
    recurring_req: {
      pairs_to_edit: pairsToEdit || [],
      pairs_to_add: newPairs || [],
    },
  };
};

export const transformPoints = (
  oldScheduling: ScheduleDefinition,
  newScheduling: ScheduleDefinition
): ScheduleUpdateReq => {
  const newpoints = newScheduling.time_points?.pts;

  return {
    time_points_req: {
      to_add: findNewInArray(oldScheduling.time_points?.pts || [], newpoints || []),
      to_delete: findDeletedInArray(oldScheduling.time_points?.pts || [], newpoints || []),
    },
  };
};
