import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';

import { CFRole } from 'domain/general.types';

import { QuestionType } from 'services/assistant/assistant.types';

import CFConfirmableButton from 'components/CFConfirmableButton';
import CFButtonGroup, { ButtonGroupOption } from 'components/CFButtonGroup';
import CFButton from 'components/buttons/CFButton';

import AssistantWall from './AssistantWall';
import AssistantSplash from './AssistantSplash';
import PromptHints from './PromptHints';
import AssistantInput from './AssistantInput';

import { useMessagesContext } from './context/useMessagesContext';

import './assistant.scss';

interface Props {}

const AssistantInternal = ({}: Props) => {
  const textareaRef = React.createRef<HTMLTextAreaElement>();
  const [questionType, setQuestionType] = useState(QuestionType.Analytics);

  const { messages, reset, embedded, currentMessage } = useMessagesContext();

  useEffect(() => {
    textareaRef.current?.focus();
  }, [currentMessage]);

  useEffect(() => {
    textareaRef.current?.focus();
  }, [messages]);

  const handleReset = useCallback(async () => {
    reset();
  }, []);

  const questionTypeOptions = [
    { label: 'Analytics', value: QuestionType.Analytics },
    { label: 'Docs', value: QuestionType.Docs },
  ];

  const questionTypeSelect = useMemo(() => {
    return questionTypeOptions.find((item) => item.value === questionType) as ButtonGroupOption;
  }, [questionType]);

  return (
    <div className={classnames('assistant', { embedded: embedded })}>
      {messages.length ? <AssistantWall /> : <AssistantSplash embedded={embedded} />}

      <PromptHints />

      <div className={classnames('assistant__footer', { embedded: embedded })}>
        <AssistantInput type={questionType} />

        <div className="controls-container">
          <CFConfirmableButton question={`Are you sure you want to reset chat history?`} title={'Reset chat'}>
            <CFButton value="Reset" role={CFRole.Borderless} iconName={faArrowRotateRight} onClick={handleReset} />
          </CFConfirmableButton>

          <CFButtonGroup
            options={questionTypeOptions}
            value={questionTypeSelect}
            onSelect={(option) => setQuestionType(option.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default AssistantInternal;
