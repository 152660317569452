import React, { useCallback, useEffect, useState } from 'react';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';

import { AlgorithmClass } from 'domain/model.types';
import { AggLevel } from 'domain/stats.types';

import { getSurvivalCurves } from 'services/model/censoring.repo';
import { SurvivalFunctionContainer } from 'services/model/censoring.types';

import useURLModel from 'views/model/hooks/useURLModel';

import CFStepChart, { ScaleType } from 'components/charts/CFStepChart';
import CFNoBorderButton from 'components/buttons/CFNoBorderButton';

import { chartLineColor1 } from 'styles/colors';

import colors from 'common.scss';

import './survival-curve.scss';

interface Props {
  className?: string;
}

const SurvivalCurve = ({ className }: Props) => {
  const model = useURLModel();
  const [survivalCurves, setSurvivalCurves] = useState<SurvivalFunctionContainer[]>([]);
  const [clusterPath, setClusterPath] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!model || model.definition.algo_spec.class_name !== AlgorithmClass.Censoring) {
        return;
      }

      setLoading(true);
      const survivalCurves = await getSurvivalCurves(model.definition.id, clusterPath[clusterPath.length - 1]);
      setLoading(false);
      setSurvivalCurves(survivalCurves);
    })();
  }, [model, clusterPath]);

  const handleLineClicked = useCallback(
    (lineName: string) => {
      const clickedCurve = survivalCurves.find((curve) => `${curve.idx}` === lineName);

      if (clickedCurve?.user_id) {
        // it is already a leaf
        return;
      }
      setClusterPath((path) => [...path, parseInt(lineName)]);
    },
    [survivalCurves]
  );

  const handleBackward = useCallback(() => {
    setClusterPath((path) => [...path.slice(0, -1)]);
  }, []);

  return (
    <CFStepChart
      scale={ScaleType.Linear}
      title={'Survival functions'}
      className={className}
      color={chartLineColor1}
      data={survivalCurves.map((curve) => ({
        name: `${curve.idx}`,
        items: curve.surv_func.y.map((value: any, i) => ({ x: curve.surv_func.x[i], y: value })),
        color: curve.user_id ? colors.cfCyan : colors.cfGreen,
      }))}
      aggregationLevel={AggLevel.Day}
      isLoading={!survivalCurves}
      decimalPlaces={5}
      description={'Shows survival functions for censored users (where today is zeroth day)'}
      onLineClicked={handleLineClicked}
    >
      <CFNoBorderButton
        value={''}
        iconName={faCaretLeft}
        onClick={handleBackward}
        disabled={loading || !clusterPath.length}
      />

      <div className="survival-legend">
        <div className="survival-legend__leaf survival-legend__item"></div>
        <span>Leaf</span>
      </div>

      <div className="survival-legend">
        <div className="survival-legend__cluster survival-legend__item"></div>
        <span>Cluster</span>
      </div>
    </CFStepChart>
  );
};

export default SurvivalCurve;
