import React, { useMemo } from 'react';

import CFTitledComponent from 'components/CFTitledComponent';
import { AppModel } from 'domain/model.types';
import { timeWindowToHuman } from 'helpers/misc';

interface Props {
  model: AppModel;
}

const EmbeddingParamsViewer = ({ model }: Props) => {
  const [timeWindowValue, timeWindowUnit] = useMemo(() => {
    return timeWindowToHuman(model.definition.data_defn.embedding?.look_back_time || 0);
  }, [model]);

  return (
    <>
      <CFTitledComponent title="Loop back time">
        <span>
          {timeWindowValue} {timeWindowUnit}
        </span>
      </CFTitledComponent>

      <CFTitledComponent title="Interaction type">
        <span>{model.definition.data_defn.embedding?.interaction_type}</span>
      </CFTitledComponent>
    </>
  );
};

export default EmbeddingParamsViewer;
