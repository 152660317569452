import React, { useEffect, useState } from 'react';

import CFTitledComponent from 'components/CFTitledComponent';
import { GroupName } from 'services/intervention/intervention.types';
import { getSampleInfo } from 'services/cohort/cohort.repo';
import KeyValue from 'views/intervention/components/KeyValue';
import CFTitledSection from 'components/CFTitledSection';

import './manual-sample-info.scss';

interface Props {
  sampleSize: number;
  sampleId: string;
  className: string;
}

const ManualSample = ({ sampleSize, sampleId, className }: Props) => {
  const [groupsInfo, setGroupsInfo] = useState<Record<GroupName, number>>({});

  useEffect(() => {
    (async () => {
      if (!sampleId) {
        return;
      }

      const sampleInfo = await getSampleInfo(sampleId);
      setGroupsInfo(sampleInfo.group_cnt);
    })();
  }, [sampleId]);
  return (
    <CFTitledSection title="Participants" className={className}>
      <div className="manual-sample-info">
        <CFTitledComponent title="Manual Sample">
          <KeyValue name={'Size'} value={sampleSize} />
        </CFTitledComponent>

        <div className="groups">
          {Object.keys(groupsInfo)
            .filter((groupName) => groupName !== 'total')
            .map((groupName) => (
              <div key={groupName} className="extendable-viewer">
                {groupName} ({groupsInfo[groupName]})
              </div>
            ))}
        </div>
      </div>
    </CFTitledSection>
  );
};

export default ManualSample;
