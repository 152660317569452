import React from 'react';

import { useState, useCallback, forwardRef, useEffect, useImperativeHandle } from 'react';

import { PtrWithWindows, Transformation } from 'domain/model.types';
import { ColAddr, Trait, TraitSubject, TraitUsage } from 'domain/traits.types';

import { createTraitCode } from 'services/traits/helpers.traits';
import { Ptr } from 'services/cohort/cohort.types.api';

import TraitListItem from 'views/model/components/TraitsListItem';

import TraitsTable from 'connected-components/TraitsTable';

import CFTabs from 'components/CFTabs';
import CFTitledComponent, { TitleSize } from 'components/CFTitledComponent';
import CFTitledSection from 'components/CFTitledSection';

import { useServicesContext } from 'hooks/useServicesContext';

interface Props {
  onReady: () => void;
  onChange: (staticTraits: Trait[], dynamicTraits: Trait[]) => void;
  usage: TraitUsage;
  types: FeaturePickerTypes[];
  extraType?: FeaturePickerExtraType;
}

export enum FeaturePickerExtraType {
  Window = 'window',
  Tranformation = 'transformation',
}

export enum FeaturePickerTypes {
  Static,
  Dynamic,
}

export interface FeaturePickerRef {
  transformations: Record<Ptr, Transformation>;
  dynamic: PtrWithWindows[];
  static: ColAddr[];
}

const FeaturePicker = forwardRef<FeaturePickerRef, Props>(function FeaturePicker(
  { usage, types, extraType = FeaturePickerExtraType.Window, onReady, onChange }: Props,
  ref
) {
  const { traitSessionService: traitService } = useServicesContext();

  const [selectedDynamic, setSelectedDynamic] = useState<Trait[]>([]);
  const [selectedStatic, setSelectedStatic] = useState<Trait[]>([]);
  const [dynamicTraits, setDynamicTraits] = useState<Trait[]>([]);
  const [staticTraits, setStaticTraits] = useState<Trait[]>([]);

  const [windows, setWindows] = useState<Record<Ptr, number[]>>({});
  const [transformations, setTransformations] = useState<Record<Ptr, Transformation>>({});

  useImperativeHandle(ref, () => ({
    static: selectedStatic.map((trait) => trait.addr),
    dynamic: selectedDynamic.map((trait) => ({
      ptr: trait.addr.ptr,
      windows: windows[trait.addr.ptr],
    })),
    transformations,
  }));

  const fetchTraits = async () => {
    if (types.includes(FeaturePickerTypes.Dynamic)) {
      setDynamicTraits(await traitService.getContextDynamicTraits(TraitSubject.User, usage));
    }

    if (types.includes(FeaturePickerTypes.Static)) {
      setStaticTraits(await traitService.getBanditContextStaticTraits(TraitSubject.User));
    }

    onReady();
  };

  useEffect(() => {
    fetchTraits();
  }, []);

  const handleSelectDynamic = (
    trait: Trait,
    selected: boolean,
    traitWindows: number[],
    transformation: Transformation | undefined
  ) => {
    let newSelectedDynamic: Trait[] = [];

    if (!selected) {
      newSelectedDynamic = selectedDynamic.filter((_trait) => createTraitCode(_trait) !== createTraitCode(trait));
    } else {
      if (!selectedDynamic.find((item) => item.addr.ptr === trait.addr.ptr)) {
        newSelectedDynamic = [...selectedDynamic, trait];
      } else {
        newSelectedDynamic = [...selectedDynamic];
      }
    }

    if (traitWindows) {
      setWindows((windows) => ({ ...windows, [trait.addr.ptr]: traitWindows }));
    }

    if (transformation) {
      setTransformations((transformations) => ({ ...transformations, [trait.addr.ptr]: transformation }));
    }

    setSelectedDynamic(newSelectedDynamic);

    onChange(staticTraits, newSelectedDynamic);
  };

  const handleSelectStatic = useCallback(
    (trait: Trait) => {
      const newSelectedStatic = selectedStatic.filter((_trait) => createTraitCode(_trait) !== createTraitCode(trait));

      if (newSelectedStatic.length === selectedStatic.length) {
        newSelectedStatic.push(trait);
      }

      setSelectedStatic(newSelectedStatic);
      onChange(newSelectedStatic, selectedDynamic);
    },
    [selectedStatic]
  );

  return (
    <div className="model-features">
      <CFTabs.TabContext value="dynamic">
        <CFTabs.Tabs>
          <CFTabs.Tab value="dynamic">Dynamic Features</CFTabs.Tab>
          <CFTabs.Tab value="static">Static Features</CFTabs.Tab>
        </CFTabs.Tabs>

        <CFTabs.TabPanel value="dynamic" className="metrics-policy-tabpanel">
          <TraitsTable
            selected={selectedDynamic}
            handleSelectTrait={(row, selected, windows, transformation) =>
              handleSelectDynamic(row as Trait, selected, windows, transformation)
            }
            traits={dynamicTraits}
            showWindow={extraType === FeaturePickerExtraType.Window}
            showTransformation={extraType === FeaturePickerExtraType.Tranformation}
          />
        </CFTabs.TabPanel>

        <CFTabs.TabPanel value="static" className="metrics-policy-tabpanel">
          <TraitsTable
            selected={selectedStatic}
            handleSelectTrait={(row) => handleSelectStatic(row as Trait)}
            traits={staticTraits}
          />
        </CFTabs.TabPanel>
      </CFTabs.TabContext>
      <CFTitledSection nested={true} title={`Selected Data (${selectedDynamic.length + selectedStatic.length})`}>
        <div className="selected-metrics-section">
          <CFTitledComponent title="Dynamic Features" size={TitleSize.Big} className={'trait-list'}>
            {selectedDynamic.length ? (
              selectedDynamic.map((trait) => (
                <TraitListItem
                  key={createTraitCode(trait)}
                  trait={trait}
                  windows={windows[trait.addr.ptr]}
                  onDelete={(trait) => handleSelectDynamic(trait, false, [], undefined)}
                />
              ))
            ) : (
              <div className="empty-label">No Selected Dynamic Features</div>
            )}
          </CFTitledComponent>

          <CFTitledComponent title="Static Features" size={TitleSize.Big} className={'trait-list'}>
            {selectedStatic.length ? (
              selectedStatic.map((trait) => (
                <TraitListItem
                  key={createTraitCode(trait)}
                  trait={trait}
                  onDelete={(trait) => handleSelectStatic(trait)}
                />
              ))
            ) : (
              <div className="empty-label">No Selected Static Features</div>
            )}
          </CFTitledComponent>
        </div>
      </CFTitledSection>
    </div>
  );
});

export default FeaturePicker;
