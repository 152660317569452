import React from 'react';

import { ColAddr } from 'domain/traits.types';
import { Model } from 'domain/model.types';

import { getIdentifierFromAddr, getTraitNameFromAddr } from 'services/traits/helpers.traits';

import CFTraitItem from 'connected-components/traits/CFTraitItem';

import './features.scss';

interface Props {
  model: Model;
}

const DynamicFeatures = ({ addrs }: { addrs: ColAddr[] }) => {
  return (
    <>
      {addrs.map((addr) => (
        <CFTraitItem key={getIdentifierFromAddr(addr)} addr={addr} />
      ))}
    </>
  );
};

const ModelFeatures = ({ model }: Props) => {
  const dynamicFeaturesLength = (
    model.definition.data_defn.dynamic_features ||
    model.definition.data_defn.censoring?.dynamic_features ||
    []
  ).length;

  const staticFeatures = model.definition.data_defn.static_features || [];

  return (
    <div className="model-definition-features">
      {dynamicFeaturesLength === 0 && staticFeatures.length === 0 && (
        <div className="model-definition-parameters no-params">No features</div>
      )}

      {dynamicFeaturesLength !== 0 && (
        <div className="model-features-selected">
          <div className="title">Dynamic Features</div>
          <div className="selected-items">
            <DynamicFeatures addrs={model.definition.data_defn.dynamic_features || []} />
          </div>
        </div>
      )}
      {staticFeatures.length !== 0 && (
        <div className="model-features-selected">
          <div className="title">Static Features</div>
          <div className="selected-items">
            {staticFeatures.map((addr) => (
              <CFTraitItem key={getTraitNameFromAddr(addr)} addr={addr} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModelFeatures;
