import { useEffect } from 'react';
import { useAnalyticsContext } from '../context/useAnalyticsContext';

import sessionService from 'services/session/session.service';
import { SESSION_SUBJECT_KEY } from './useInitializedSubject';
import { SESSION_MODULE_KEY } from './useInitializedModule';
import { SESSION_COHORT_KEY } from './useInitializedCohorts';

const useSessionUpdater = (): void => {
  const { subject, module, selectedCohortIDs, explorationMode } = useAnalyticsContext();

  useEffect(() => {
    if (explorationMode) {
      return;
    }

    sessionService.saveCustomValue(SESSION_SUBJECT_KEY, subject);
  }, [subject, explorationMode]);

  useEffect(() => {
    if (explorationMode) {
      return;
    }

    sessionService.saveCustomValue(SESSION_MODULE_KEY, module);
  }, [module, explorationMode]);

  useEffect(() => {
    if (explorationMode) {
      return;
    }

    sessionService.saveCustomValue(SESSION_COHORT_KEY, selectedCohortIDs);
  }, [selectedCohortIDs, explorationMode]);
};

export default useSessionUpdater;
