import React from 'react';

import { AppModel } from 'domain/model.types';
import MonitoringSection from '../components/monitoringSection';
import ModelCohort from '../components/cohort';
import Schedule from 'connected-components/schedule';
import ModelRuns from '../components/runs';
import useCohort from 'views/segmentation/cohorts/hooks/useCohort';
import EmbeddingParamsViewer from './params';

interface Props {
  model: AppModel;
}

const ModelEmbeddingDetails = ({ model }: Props) => {
  const userCohort = useCohort({ id: `${model.definition.population_policy.cohort.cohort_id}` });
  const itemCohort = useCohort({ id: `${model.definition.data_defn.embedding?.item_policy.cohort.cohort_id}` });

  return (
    <>
      <MonitoringSection title="User Cohort">
        <ModelCohort model={model} cohort={userCohort} />
      </MonitoringSection>

      <MonitoringSection title="Item Cohort">
        <ModelCohort model={model} cohort={itemCohort} splitRatio={model.definition.population_policy.split_ratio} />
      </MonitoringSection>

      <MonitoringSection title="Params">
        <EmbeddingParamsViewer model={model} />
      </MonitoringSection>

      <MonitoringSection title="Runs">
        <ModelRuns model={model} />
      </MonitoringSection>

      <Schedule id={model.schedule.id} label="model" />
    </>
  );
};

export default ModelEmbeddingDetails;
