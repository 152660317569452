import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CFInput, { CFInputType } from 'components/CFInput';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import CFTitledComponent from 'components/CFTitledComponent';

import { AlgorithmClass } from 'services/intervention/intervention.types';
import { Trait, TraitSubject, TraitUsage } from 'domain/traits.types';

import { useServicesContext } from 'hooks/useServicesContext';
import { getDisplayName, getIdentifier, getTags } from 'services/traits/helpers.traits';

import { Steps, useModelContext } from '../../useContext';
import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import './censoring-target.scss';

const CensoringTarget = () => {
  const { traitSessionService: traitService } = useServicesContext();

  const { definition, setTarget, updateReadiness } = useModelContext();

  const [selectedEvent, setSelectedEvent] = useState<Trait | null>(null);
  const [eventTraits, setEventTraits] = useState<Trait[]>([]);
  const [validationDelta, setValidationDelta] = useState(0);

  const fetchTraits = async () => {
    setEventTraits(await traitService.getTraits({ subject: TraitSubject.User, usage: TraitUsage.Event }));
  };

  useEffect(() => {
    fetchTraits();
  }, []);

  useEffect(() => {
    if (!definition) {
      updateReadiness(false, Steps.Target);
      return;
    }

    if (definition.class === AlgorithmClass.Censoring && selectedEvent !== null) {
      setTarget({
        event: selectedEvent.addr,
        validation_delta: validationDelta,
      });

      updateReadiness(true, Steps.Target);
    }
  }, [selectedEvent, validationDelta]);

  const indexedEventTraits = useMemo(
    () => eventTraits.reduce<Record<string, Trait>>((acc, trait) => ({ ...acc, [getIdentifier(trait)]: trait }), {}),
    [eventTraits]
  );

  const handleSelectEventTrait = useCallback(
    ([item]: SelectableItem[]) => {
      setSelectedEvent(indexedEventTraits[item.value]);
    },
    [indexedEventTraits]
  );

  return (
    <InterventionSection
      name={Steps.Target}
      title={'Target'}
      subtitle="Select the targets for your model."
      className="censoring-target"
    >
      <CFTitledComponent title={'Event'}>
        <CFSelectLegacy
          onSelected={handleSelectEventTrait}
          options={eventTraits.map((trait) => ({
            value: getIdentifier(trait),
            label: getDisplayName(trait),
            tags: getTags(trait),
          }))}
          placeholder="Select event"
        />
      </CFTitledComponent>

      <CFTitledComponent title={'Validation delta'}>
        <CFInput
          type={CFInputType.Number}
          defaultValue={0}
          onChange={(e) => setValidationDelta(parseInt(e.target.value))}
        />
      </CFTitledComponent>
    </InterventionSection>
  );
};

export default CensoringTarget;
