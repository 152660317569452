import { FilterAPI, FilterGroupOperation, Leaf, Tree } from '../cohort.types.api';
import Filter from './Filter';

class FilterSet {
  public tree: Tree<Filter>;

  constructor(tree: Tree) {
    this.tree = this.hydrate(tree);
  }

  private hydrate(tree: Tree<FilterAPI>): Tree<Filter> {
    return {
      op: tree.op,
      nodes: (tree.nodes || []).map((node) => {
        if (node.tree) {
          return { tree: this.hydrate(node.tree) };
        } else {
          return {
            leaf: {
              op: (node.leaf as Leaf<Filter>).op,
              filters: (node.leaf as Leaf<Filter>).filters
                .map((filter) => new Filter(filter))
                .filter((filter) => (filter as Filter).isValid()),
            },
          };
        }
      }),
    };
  }

  private marshall(tree: Tree<Filter>): Tree<FilterAPI> {
    return {
      op: tree.op,
      nodes: tree.nodes.map((node) => {
        if (node.tree) {
          return { tree: this.marshall(node.tree) };
        } else {
          return {
            leaf: {
              op: (node.leaf as Leaf<Filter>).op,
              filters: (node.leaf as Leaf<Filter>).filters.map((filter) => ({
                op: filter.op,
                val: filter.val,
                ptr: filter.ptr || 'unknown ptr',
                model_id: filter.modelId,
              })),
            },
          };
        }
      }),
    };
  }

  public getLegacy(): Tree<FilterAPI> {
    return this.marshall(this.tree);
  }

  public addExtraFilter(leaf: Leaf<Filter>) {
    (this.tree.op = FilterGroupOperation.And),
      this.tree.nodes.push({
        leaf: {
          op: leaf.op,
          filters: leaf.filters ? leaf.filters.filter((filter) => filter.isValid()) : [],
        },
      });
  }
}

export default FilterSet;
