import React from 'react';

import KeyValue from 'views/intervention/components/KeyValue';

import CohortService from 'services/cohort/cohort.service';
import TraitService from 'services/traits/traitSession.service';
import FilterSet from 'services/cohort/domain/FilterSet';
import Cohort from 'services/cohort/domain/Cohort';
import { FilterAPI, FilterGroupOperation } from 'services/cohort/cohort.types.api';

import SampleViewer from 'connected-components/sampleViewer';
import TreeViewer from 'connected-components/TreeViewer';

import CFTitledSection from 'components/CFTitledSection';

interface Props {
  cohort: Cohort;
  sampleSize: number;
  extraFilters: FilterAPI[];
  sampleId: string;
  className: string;
  cohortService: CohortService;
  traitService: TraitService;
}

const CohortDefinition = ({ className, cohort, cohortService, sampleId, sampleSize, extraFilters }: Props) => {
  return (
    <CFTitledSection title="Participants" className={className}>
      <KeyValue name={'Name'} value={cohort.name} />
      <div className="key"> Definition: </div>
      <TreeViewer value={cohort.tree} />

      {extraFilters?.length > 0 ? (
        <>
          <div className="key"> Extra filters: </div>
          <TreeViewer
            value={
              new FilterSet({
                op: FilterGroupOperation.And,
                nodes: [{ leaf: { filters: extraFilters, op: FilterGroupOperation.And } }],
              })
            }
          />
        </>
      ) : (
        <></>
      )}

      <CFTitledSection nested={true} title={`Sampled subjects (${sampleSize})`}>
        <SampleViewer sampleId={{ value: sampleId }} cohortService={cohortService} />
      </CFTitledSection>
    </CFTitledSection>
  );
};

export default CohortDefinition;
