import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import { TraitSubject } from 'domain/traits.types';
import { NewModelRequest } from 'domain/model.types';
import { CFRole } from 'domain/general.types';

import { getCurrentProject } from 'services/session/session.service';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import SchedulingBuilder, { SchedulingBuilderRef } from 'connected-components/SchedulingBuilder';

import Wizard from 'components/CFWizard';
import CFButton from 'components/buttons/CFButton';
import { ToastType } from 'components/CFToast/types';

import ModelCohortPicker, { ModelCohortType, SamplingType } from './cohort';
import ModelDefinition from './definition';

import { useServicesContext } from 'hooks/useServicesContext';
import { Steps, useModelContext } from './useContext';
import { cohortSelectorDescription } from './constants';

import { create as createModel } from 'services/model/model.repo';

import { useToast } from 'hooks';
import { CFRoutes } from 'routes';
import { AlgorithmClass } from 'services/intervention/intervention.types';
import EmbeddingParams, { EmbeddingParamsRef } from './embeddingParams';

const NewEmbeddingModel = () => {
  const { modelService, cohortService } = useServicesContext();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const { definition, readiness, userCohort, itemCohort, updateReadiness } = useModelContext();

  const schedulingRef = useRef<SchedulingBuilderRef>() as React.MutableRefObject<SchedulingBuilderRef>;
  const embeddingRef = useRef<EmbeddingParamsRef>() as React.MutableRefObject<EmbeddingParamsRef>;

  const [loading, setLoading] = useState(false);

  const handleCreateModel = async () => {
    if (!userCohort || !definition || !itemCohort) {
      return;
    }

    const cohort = cohortService.getCohort(itemCohort.cohort.cohort_id.toString());

    const request: NewModelRequest = {
      schedule: schedulingRef.current.value(),
      definition: {
        name: definition.name,
        algo_spec: {
          class_name: definition.class,
          algo_name: definition.algorithm,
        },
        population_policy: userCohort,
        data_defn: {
          embedding: {
            item_subject_type: cohort.subject_type,
            item_policy: itemCohort,
            interaction_type: embeddingRef.current.value().interactionType,
            look_back_time: embeddingRef.current.value().loobackTime,
          },
        },
      },
    };

    try {
      setLoading(true);
      await createModel(request);

      addToast('Model created', ToastType.SUCCESS, 5000);

      navigate(CFRoutes.model);
    } catch (err) {
      addToast(`Impossible to create model ${(err as any).message}`, ToastType.ERROR, 5000);
      setLoading(false);

      console.log('Error creating model: ', err);
    }
  };

  return (
    <div className="new-model">
      <Wizard>
        <Wizard.Step name={Steps.Definition} ready={readiness.definition}>
          <ModelDefinition modelService={modelService} defaultClass={AlgorithmClass.Embedding} />
        </Wizard.Step>

        <Wizard.Step name={Steps.UserCohort} ready={readiness[Steps.UserCohort]} displayName="User cohort">
          <ModelCohortPicker
            showSplitRatio={false}
            type={ModelCohortType.User}
            title="User cohort"
            subtitle={definition ? cohortSelectorDescription[definition.class] : ''}
            subjectTypes={[TraitSubject.User]}
            sampling={SamplingType.Optional}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.ItemCohort} ready={readiness[Steps.ItemCohort]} displayName="Item cohort">
          <ModelCohortPicker
            title="Item cohort"
            subtitle="Select the items for your embedding model"
            type={ModelCohortType.Item}
            subjectTypes={getCurrentProject()?.subjects || []}
            showSplitRatio={false}
            sampling={SamplingType.Optional}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.Parameters} ready={readiness.parameters} displayName="Parameters">
          <EmbeddingParams ref={embeddingRef} />
        </Wizard.Step>

        <Wizard.Step name={Steps.Schedule} ready={readiness.scheduling}>
          <InterventionSection name={Steps.Schedule} title="Scheduling" subtitle="Set schedules for your model.">
            <SchedulingBuilder ref={schedulingRef} onReady={(ready) => updateReadiness(ready, Steps.Schedule)} />
          </InterventionSection>
        </Wizard.Step>

        <Wizard.Footer>
          <div className="controls">
            <CFButton
              value="Create Model"
              role={CFRole.Primary}
              disabled={Object.values(readiness).some((ready) => !ready)}
              isLoading={loading}
              onClick={handleCreateModel}
              iconCustom={<img src={pagePlusIcon} />}
            />
          </div>
        </Wizard.Footer>
      </Wizard>
    </div>
  );
};

export default NewEmbeddingModel;
