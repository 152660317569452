import React, { useEffect, useMemo, useState } from 'react';

import CFLineChart from 'components/charts/CFLineChart';
import { AggLevel } from 'domain/stats.types';
import CFSelect from 'components/CFSelect';

import './dynamic-chart.scss';

interface Props {
  response: Record<string, string>[];
}

const DynamicChart = ({ response }: Props) => {
  const [horizontalAxisField, setHorizontalAxisField] = useState('');
  const [verticalAxisField, setVerticalAxisField] = useState('');

  const columnNames = useMemo(() => {
    return Object.keys(response[0]).filter((item) => !!item);
  }, [response]);

  useEffect(() => {
    const value = columnNames.find((item) => !isNaN(Number(response[0][item])));

    setVerticalAxisField(value || '');
  }, []);

  useEffect(() => {
    const timeField = columnNames.find(
      (item) => !isNaN(new Date(response[0][item]).getTime()) && isNaN(Number(response[0][item]))
    );

    setHorizontalAxisField(timeField || '');
  }, []);

  const horizontalOptions = useMemo(() => {
    return columnNames.filter((item) => item !== verticalAxisField);
  }, [columnNames, verticalAxisField]);

  const verticalOptions = useMemo(() => {
    return columnNames.filter((item) => item !== horizontalAxisField);
  }, [columnNames, horizontalAxisField]);

  const series = useMemo(() => {
    if (!verticalAxisField || !horizontalAxisField) {
      return [];
    }

    return [
      {
        items: response
          .map((row) => ({ time: row[horizontalAxisField], value: Number(row[verticalAxisField]) }))
          .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()),
        name: verticalAxisField,
      },
    ];
  }, [response, verticalAxisField, horizontalAxisField]);

  return (
    <div className="dynamic-chart">
      <div className="dynamic-chart__controls">
        X-Axis
        <CFSelect
          options={horizontalOptions.map((option) => ({ value: option, label: option }))}
          isMulti={false}
          value={{ value: horizontalAxisField, label: horizontalAxisField }}
          onSelected={(item) => setHorizontalAxisField(item.value)}
        />
        Y-Axis
        <CFSelect
          options={verticalOptions.map((option) => ({ value: option, label: option }))}
          isMulti={false}
          value={{ value: verticalAxisField, label: verticalAxisField }}
          onSelected={(item) => setVerticalAxisField(item.value)}
        />
      </div>
      <CFLineChart data={series} aggregationLevel={AggLevel.Day} isLoading={false} />
    </div>
  );
};

export default DynamicChart;
