import React from 'react';

import { CFRoutes } from 'routes';
import { useToast } from 'hooks';

import useCFNavigation from 'hooks/useCFNavigation';

import { AlgorithmClass } from 'domain/model.types';

import useURLModel from 'views/model/hooks/useURLModel';

import { remove as removeModel } from 'services/model/model.repo';
import { AuthAction, isAllowedTo } from 'services/authorization.service';

import ModelCensoringDetails from './ClassCensoring/model-censoring-details';
import ModelRecommenderDetails from './ClassRecommender/model-recommender-details';
import ModelEmbeddingDetails from './ClassEmbedding/model-embedding-details';
import ModelBanditDefinition from './ClassBandit';

import { ToastType } from 'components/CFToast/types';
import ModelDetails from '../../components/ModelDetails';
import CFConfirmableButton from 'components/CFConfirmableButton';
import CFTrashButton from 'components/buttons/CFTrashButton';
import CFSpinner from 'components/CFSpinner';
import MonitoringSection from './components/monitoringSection';

import { Tabs } from '../../tabs';

import ModelDetailLayout from '../layout';

import colors from 'common.scss';

import './model-definition.scss';

const ModelDefinition = () => {
  const navigate = useCFNavigation();

  const { addToast } = useToast();

  const model = useURLModel();

  const handleRemoveModel = () => {
    if (!model?.definition?.id) {
      return;
    }

    removeModel(model.definition.id)
      .then(() => {
        navigate(CFRoutes.model);
        addToast(`Model deleted`, ToastType.SUCCESS);
      })
      .catch((err) => {
        addToast(`Error deleting model`, ToastType.ERROR);
        console.log('Error deleting model: ', err);
      });
  };

  const modelActions = (
    <div className="actions">
      {isAllowedTo(AuthAction.CreateModel) && model?.definition?.purpose !== 'inv' && (
        <CFConfirmableButton title={'Remove model'} question={'Are you sure to remove this model?'}>
          <CFTrashButton onClick={() => handleRemoveModel()} />
        </CFConfirmableButton>
      )}
    </div>
  );

  return (
    <ModelDetailLayout className="model-monitor-definition" tab={Tabs.Definition}>
      {!model && (
        <div>
          <CFSpinner size={70} color={colors.cfCyan} stroke={4} />
        </div>
      )}
      <div className="model-definition-container">
        {model && (
          <MonitoringSection title={`Model #${model.definition.id}`} actionSection={modelActions}>
            <ModelDetails model={model} />
          </MonitoringSection>
        )}

        {model && model.definition.algo_spec.class_name === AlgorithmClass.Censoring && (
          <ModelCensoringDetails model={model} />
        )}

        {model && model.definition.algo_spec.class_name === AlgorithmClass.Recommender && (
          <ModelRecommenderDetails model={model} />
        )}

        {model && model.definition.algo_spec.class_name === AlgorithmClass.Embedding && (
          <ModelEmbeddingDetails model={model} />
        )}

        {model && model.definition.algo_spec.class_name === AlgorithmClass.Bandit && (
          <ModelBanditDefinition model={model} />
        )}
      </div>
    </ModelDetailLayout>
  );
};

export default ModelDefinition;
