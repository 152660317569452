import { RepoConfig } from 'repositories/types';
import { put as httpPut } from '../../repositories/drivers/http';
import { transformPoints, transformRecurring } from './mappers';
import { ScheduleDefinition, ScheduleUpdateReq } from './schedulting.types.api';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/scheduler';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const updateScheduling = async (
  id: number,
  oldScheduling: ScheduleDefinition,
  newScheduling: ScheduleDefinition
) => {
  const config = {};
  let body: ScheduleUpdateReq;

  if (newScheduling.recurring) {
    body = transformRecurring(oldScheduling, newScheduling);
  } else {
    body = transformPoints(oldScheduling, newScheduling);
  }

  await httpPut(
    `${serverBaseUrl}${path}/definition/update/${id}?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
    body,
    config
  );
};
