import { useEffect, useState } from 'react';

import { useServicesContext } from 'hooks/useServicesContext';
import Cohort from 'services/cohort/domain/Cohort';

interface Props {
  id: string;
}

const useCohort = ({ id }: Props) => {
  const [cohort, setCohort] = useState<Cohort>();
  const { cohortService } = useServicesContext();

  useEffect(() => {
    (async () => {
      const cohort = await cohortService.getRemoteCohort(Number(id));
      setCohort(cohort);
    })();
  }, []);

  return cohort;
};

export default useCohort;
