import React, { useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { CFRole } from 'domain/general.types';
import CFSpinner from 'components/CFSpinner';

import classNames from 'classnames';
import CFTooltip, { CFTooltipPositions } from 'components/CFTooltip';

import './cf-button.scss';

interface Props {
  value: JSX.Element | string;
  disabled?: boolean;
  iconName?: IconDefinition;
  iconCustom?: JSX.Element;
  role?: CFRole;
  testId?: string;
  isLoading?: boolean;
  animate?: boolean;
  description?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void;
}

const CFButton = ({
  onClick,
  value,
  iconName,
  iconCustom,
  testId,
  role = CFRole.Secondary,
  disabled = false,
  animate = false,
  isLoading = false,
  description = '',
  type = 'submit',
  buttonRef,
}: Props) => {
  const buttonId = useRef(Math.round(Math.random() * 10 ** 6));

  return (
    <CFTooltip
      description={description}
      position={CFTooltipPositions.Right}
      anchor={`.button-${buttonId.current.toString()}`}
    >
      <button
        id={`id-${buttonId.current.toString()}`}
        ref={buttonRef}
        type={type}
        className={classNames('cf-button', `button-${buttonId.current.toString()}`, `role-${role}`, {
          'is-loading is-disabled': isLoading,
          'is-disabled': disabled,
        })}
        onClick={onClick}
        disabled={isLoading || disabled}
        aria-disabled={isLoading || disabled}
        data-testid={testId}
      >
        {isLoading ? (
          <CFSpinner />
        ) : iconCustom ? (
          iconCustom
        ) : iconName !== undefined ? (
          <FontAwesomeIcon className={classNames('button-icon', { animate })} icon={iconName} size="lg" />
        ) : (
          ''
        )}
        {value !== '' ? <span className="cf-button-input">{value}</span> : ''}
      </button>
    </CFTooltip>
  );
};

export default CFButton;
