import { sessionQueryInfo } from 'hooks/useCFNavigation';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { URL_PARAMS } from '../constants';
import { useAnalyticsContext } from '../context/useAnalyticsContext';

const useURLUpdater = () => {
  const { subject, module, selectedCohortIDs, explorationMode, traitsState, startDate, endDate, aggLevel } =
    useAnalyticsContext();
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (explorationMode) {
      return;
    }

    setSearchParams({
      ...sessionQueryInfo(),
      [URL_PARAMS.Subject]: subject,
      [URL_PARAMS.Module]: module,
      [URL_PARAMS.Cohort]: selectedCohortIDs.join(','),
      [URL_PARAMS.Trait]: (traitsState.traits[aggLevel] || []).join(','),
      [URL_PARAMS.StartDate]: startDate,
      [URL_PARAMS.EndDate]: endDate,
    });
  }, [subject, module, selectedCohortIDs, traitsState, aggLevel, startDate, endDate, explorationMode]);
};

export default useURLUpdater;
