import { TimeRFC3999 } from 'domain/general.types';

export type UploadId = number;

export type CreateUploadDataAPI = {
  doc_type: string;
  title: string;
  total_split: number;
};

export type UploadSplitAPI = {
  doc_id: number;
  split_id: number;
  content: string;
};

export interface DocAPI {
  id: number;
  pid: number;
  title: string;
  doc_type: string;
  total_split: number;
  processed: boolean;
  uploaded: boolean;
  created_at: TimeRFC3999;
  created_by: string;
}

export interface RetrievedChunkAPI {
  chunk_type: string;
  chunk_ref: string;
  doc_id: number;
  doc_name: string;
  page_num: number;
  chunk: string;
  score: number;
}

export enum MIMEType {
  PDF = 'application/pdf',
  TXT = 'text/plain',
  JSON = 'application/json',
}

export const DocType = {
  [MIMEType.PDF]: 'pdf',
  [MIMEType.TXT]: 'txt',
  [MIMEType.JSON]: 'unstructured.pdf.json',
};

export enum SearchType {
  Image = 'Image',
  Table = 'Table',
}
