import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CFRoutes } from 'routes';

import { useToast } from 'hooks';
import { useServicesContext } from 'hooks/useServicesContext';
import pagePlusIcon from 'assets/icons/pagePlus.svg';

import { Steps, useModelContext } from './useContext';

import { ColAddr, TraitSubject } from 'domain/traits.types';
import { CensoringTarget, NewModelRequest } from 'domain/model.types';
import { CFRole } from 'domain/general.types';

import { AlgorithmName } from 'services/intervention/intervention.types';
import { create as createModel } from 'services/model/model.repo';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import SchedulingBuilder, { SchedulingBuilderRef } from 'connected-components/SchedulingBuilder';

import { ToastType } from 'components/CFToast/types';
import CFButton from 'components/buttons/CFButton';
import Wizard from 'components/CFWizard';

import CensoringModelFeatures, { CensoringModelFeaturesRef } from './features/censoringFeatures';
import ModelCensoringTarget from './target/CensoringTarget';
import ModelCohortPicker, { ModelCohortType, SamplingType } from './cohort';
import ModelDefinition from './definition';
import { cohortSelectorDescription } from './constants';

const NewCensoringModel = () => {
  const { modelService, traitSessionService } = useServicesContext();

  const [loading, setLoading] = useState(false);

  const { definition, readiness, userCohort, target, updateReadiness } = useModelContext();

  const { addToast } = useToast();
  const navigate = useNavigate();

  const schedulingRef = useRef<SchedulingBuilderRef>() as React.MutableRefObject<SchedulingBuilderRef>;
  const censoringFeaturesRef = useRef<CensoringModelFeaturesRef>() as React.MutableRefObject<CensoringModelFeaturesRef>;

  const handleCreateModel = async () => {
    if (!userCohort || !definition) {
      return;
    }

    const censoring = {
      event: (target as CensoringTarget).event,
      validation_delta: (target as CensoringTarget).validation_delta,
      dynamic_features: censoringFeaturesRef.current.value().dynamic_features,
      transformations: censoringFeaturesRef.current.value().transformations, // in case of RDSM, only transformations here
    };

    let dynamicFeatures: ColAddr[] = [];
    let staticFeatures = censoringFeaturesRef.current.value().static_features || [];

    if (definition?.algorithm === AlgorithmName.DeepRecurrentSurvivalMachines) {
      dynamicFeatures = (censoring?.dynamic_features || []).map(
        (feat) => traitSessionService.getTraitDefinition(feat.ptr).addr
      );

      staticFeatures = censoringFeaturesRef.current.value().static_features || [];
    }

    const request: NewModelRequest = {
      schedule: schedulingRef.current.value(),
      definition: {
        name: definition.name,
        algo_spec: {
          class_name: definition.class,
          algo_name: definition.algorithm,
        },
        population_policy: userCohort,
        data_defn: {
          dynamic_features: dynamicFeatures,
          static_features: staticFeatures,
          censoring,
        },
      },
    };

    try {
      setLoading(true);
      await createModel(request);

      addToast('Model created', ToastType.SUCCESS, 5000);

      navigate(CFRoutes.model);
    } catch (err) {
      addToast(`Impossible to create model ${(err as any).message}`, ToastType.ERROR, 5000);
      setLoading(false);

      console.log('Error creating model: ', err);
    }
  };

  return (
    <div className="new-model">
      <Wizard>
        <Wizard.Step name={Steps.Definition} ready={readiness.definition}>
          <ModelDefinition modelService={modelService} />
        </Wizard.Step>

        <Wizard.Step name={Steps.UserCohort} ready={readiness[Steps.UserCohort]} displayName="User cohort">
          <ModelCohortPicker
            showSplitRatio={false}
            type={ModelCohortType.User}
            title="User cohort"
            subtitle={definition ? cohortSelectorDescription[definition.class] : ''}
            subjectTypes={[TraitSubject.User]}
            sampling={SamplingType.Optional}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.Features} ready={readiness.features}>
          <CensoringModelFeatures ref={censoringFeaturesRef} />
        </Wizard.Step>

        <Wizard.Step name={Steps.Target} ready={readiness.target}>
          <ModelCensoringTarget />
        </Wizard.Step>

        <Wizard.Step name={Steps.Schedule} ready={readiness.scheduling}>
          <InterventionSection name={Steps.Schedule} title="Scheduling" subtitle="Set schedules for your model.">
            <SchedulingBuilder ref={schedulingRef} onReady={(ready) => updateReadiness(ready, Steps.Schedule)} />
          </InterventionSection>
        </Wizard.Step>

        <Wizard.Footer>
          <div className="controls">
            <CFButton
              value="Create Model"
              role={CFRole.Primary}
              disabled={Object.values(readiness).some((ready) => !ready)}
              isLoading={loading}
              onClick={handleCreateModel}
              iconCustom={<img src={pagePlusIcon} />}
            />
          </div>
        </Wizard.Footer>
      </Wizard>
    </div>
  );
};

export default NewCensoringModel;
