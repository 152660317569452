import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { diff, runSync } from 'services/sync/sync.repo';
import { AutoCohortDiff, SyncDiff, TableDiff } from 'services/sync/sync.types';
import { AuthAction, isAllowedTo } from 'services/authorization.service';

import CFModal from 'components/CFModal';
import CFTabs from 'components/CFTabs';
import CFDataTable from 'components/CFDataTable';
import { Column, ColumnType } from 'components/CFTable';
import CFButton from 'components/buttons/CFButton';
import { ToastType } from 'components/CFToast/types';

import useToast from 'hooks/useToast';

import './sync-status.scss';

const SyncStatus = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [syncData, setSyncData] = useState<SyncDiff>();

  const [traitPage, setTraitPage] = useState<number>(0);
  const [cohortPage, setCohortPage] = useState<number>(0);

  const [pageSize, setPageSize] = useState<number>(10);

  // eslint-disable-next-line
  const [detailedPageSize, setDetailedPageSize] = useState(10);

  const { addToast } = useToast();

  useEffect(() => {
    (async () => {
      const data = await diff();

      setSyncData(data);
    })();
  }, []);

  const handleOpenSyncInfo = useCallback(() => {
    setShowDetail(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowDetail(false);
  }, []);

  const handleSync = useCallback(async () => {
    if (!syncData) {
      return;
    }

    try {
      await runSync(syncData);
      setShowDetail(false);
      setSyncData(undefined); // we are assuming that if "run" is ok, there is no data to show
      addToast('Synchronized', ToastType.SUCCESS);
    } catch {
      addToast('Error running sync', ToastType.ERROR);
    }
  }, [syncData]);

  const getCohortColumns = (): Column[] => [
    {
      title: 'PID',
      field: 'pid',
      type: ColumnType.NUMBER,
    },
    {
      title: 'to_add',
      field: 'to_add',
      type: ColumnType.OBJECT,
      renderCell: (row) => (row as AutoCohortDiff).to_add?.length || 0,
    },
    {
      title: 'to_drop',
      field: 'to_drop',
      type: ColumnType.OBJECT,
      renderCell: (row) => (row as AutoCohortDiff).to_drop?.length || 0,
    },
    {
      title: '',
      field: '',
      type: ColumnType.OBJECT,
      expandable: true,
      renderCell: (row) => {
        const addCohorts = (row as AutoCohortDiff).to_add;
        const dropCohorts = (row as AutoCohortDiff).to_drop;

        return (
          <CFTabs.TabContext value="cohorts-add">
            <CFTabs.Tabs>
              <CFTabs.Tab value="cohorts-add"> Add </CFTabs.Tab>
              <CFTabs.Tab value="cohorts-drop"> Drop </CFTabs.Tab>
            </CFTabs.Tabs>

            <CFTabs.TabPanel value="cohorts-add" className="sync-tabpanel">
              {(addCohorts || []).map((cohort) => (
                <div key={cohort.id}>
                  [{cohort.id}] {cohort.name} ({cohort.meta?.sub_cnt || 'unknown number of subjects'})
                </div>
              ))}
            </CFTabs.TabPanel>

            <CFTabs.TabPanel value="cohorts-drop" className="sync-tabpanel">
              {(dropCohorts || []).map((cohort) => (
                <div key={cohort.id}>
                  [{cohort.id}] {cohort.name} ({cohort.meta?.sub_cnt || 'unknown number of subjects'})
                </div>
              ))}
            </CFTabs.TabPanel>
          </CFTabs.TabContext>
        );
      },
    },
  ];

  const getDetailedTraitColumns = (): Column[] => [
    {
      title: 'Type',
      field: 'dtype',
      type: ColumnType.STRING,
    },
    {
      title: 'Name',
      field: 'name',
      type: ColumnType.STRING,
    },
    {
      title: 'Schema',
      field: 'schema',
      type: ColumnType.STRING,
    },
    {
      title: 'table',
      field: 'table',
      type: ColumnType.STRING,
    },
  ];

  const getTraitColumns = (): Column[] => [
    {
      title: 'Schema',
      field: 'schema',
      type: ColumnType.STRING,
    },
    {
      title: 'Table',
      field: 'table_name',
      type: ColumnType.STRING,
    },
    {
      title: 'Table Not Exists',
      field: 'table_not_exist',
      type: ColumnType.BOOLEAN,
    },
    {
      title: 'Index diff',
      field: 'index_diff',
      type: ColumnType.BOOLEAN,
    },
    {
      title: 'Add',
      field: 'add',
      type: ColumnType.OBJECT,
      renderCell: (row) => (row as TableDiff).add?.length || 0,
    },
    {
      title: 'Drop',
      field: 'drop',
      type: ColumnType.OBJECT,
      renderCell: (row) => (row as TableDiff).drop?.length || 0,
    },
    {
      title: '',
      field: '',
      type: ColumnType.OBJECT,
      expandable: true,
      renderCell: (row) => {
        const addTraits = (row as TableDiff).add || [];
        const dropTraits = (row as TableDiff).drop || [];

        return (
          <CFTabs.TabContext value="traits-add">
            <CFTabs.Tabs>
              <CFTabs.Tab value="traits-add"> Add </CFTabs.Tab>
              <CFTabs.Tab value="traits-drop"> Drop </CFTabs.Tab>
            </CFTabs.Tabs>

            <CFTabs.TabPanel value="traits-add" className="sync-tabpanel">
              <CFDataTable
                key={''}
                total={addTraits.length || 0}
                headers={getDetailedTraitColumns()}
                data={addTraits}
                onPaginated={onDetailPaginated}
              />
            </CFTabs.TabPanel>

            <CFTabs.TabPanel value="traits-drop" className="sync-tabpanel">
              <CFDataTable
                key={''}
                total={dropTraits.length || 0}
                headers={getDetailedTraitColumns()}
                data={dropTraits}
                onPaginated={onDetailPaginated}
              />
            </CFTabs.TabPanel>
          </CFTabs.TabContext>
        );
      },
    },
  ];

  const onDetailPaginated = (page: number, size: number) => {
    setDetailedPageSize(page - 1);
    setPageSize(size);
  };

  const onTraitPaginated = (page: number, size: number) => {
    setTraitPage(page - 1);
    setPageSize(size);
  };

  const onCohortPaginated = (page: number, size: number) => {
    setCohortPage(page - 1);
    setPageSize(size);
  };

  const traitsInfo = useMemo(() => {
    return (syncData?.trait_diff || [])
      .map((item, i) => ({ ...item, id: i }))
      .slice(traitPage * pageSize, (traitPage + 1) * pageSize);
  }, [syncData, traitPage, pageSize]);

  const cohortsInfo = useMemo(() => {
    return (syncData?.cohort_diff || [])
      .map((item, i) => ({ ...item, id: i }))
      .slice(cohortPage * pageSize, (cohortPage + 1) * pageSize);
  }, [syncData, cohortPage, pageSize]);

  const hasData = useMemo(() => {
    return (syncData?.cohort_diff || []).length || (syncData?.trait_diff || []).length;
  }, [syncData]);

  return (
    <>
      <div className="sync-status" onClick={handleOpenSyncInfo}>
        {hasData ? <FontAwesomeIcon className="sync-status__icon" icon={faExclamation} size="xl" /> : ''}
      </div>
      {showDetail && (
        <CFModal onClose={handleClose} className="detailed-sync-status">
          <CFTabs.TabContext value="traits">
            <CFTabs.Tabs>
              <CFTabs.Tab value="traits"> Traits </CFTabs.Tab>
              <CFTabs.Tab value="cohorts"> Cohorts </CFTabs.Tab>
            </CFTabs.Tabs>

            <CFTabs.TabPanel value="traits" className="sync-tabpanel">
              <CFDataTable
                key={''}
                total={syncData?.trait_diff?.length || 0}
                headers={getTraitColumns()}
                data={traitsInfo}
                onPaginated={onTraitPaginated}
              />
            </CFTabs.TabPanel>

            <CFTabs.TabPanel value="cohorts" className="sync-tabpanel">
              <CFDataTable
                key={''}
                total={syncData?.cohort_diff?.length || 0}
                headers={getCohortColumns()}
                data={cohortsInfo}
                onPaginated={onCohortPaginated}
              />
            </CFTabs.TabPanel>
          </CFTabs.TabContext>

          <div className="detailed-sync-status__footer">
            {isAllowedTo(AuthAction.SynchronizeRun) && <CFButton value={'Sync'} onClick={handleSync} />}
          </div>
        </CFModal>
      )}
    </>
  );
};

export default SyncStatus;
