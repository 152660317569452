import { TimeRFC3999 } from 'domain/general.types';

export interface PairToEdit {
  idx: number;
  delete: boolean;
  new_end: TimeRFC3999;
}

export interface StartEndPair {
  start: TimeRFC3999;
  dur: number;
  end?: TimeRFC3999;
}

export interface RecurringUpdateReq {
  pairs_to_edit: PairToEdit[];
  pairs_to_add: StartEndPair[];
}

export interface TimePointsUpdateReq {
  to_add: TimeRFC3999[];
  to_delete: TimeRFC3999[];
}

export enum ScheduleType {
  TimePoints = 'time_points',
  Recurring = 'recurring',
}
export interface Recurring {
  pairs: StartEndPair[];
}

export interface TimePoints {
  pts: string[];
}

export interface ScheduleDefinition {
  tz: string;
  recurring?: Recurring;
  time_points?: TimePoints;
}

export interface ScheduleUpdateReq {
  time_points_req?: TimePointsUpdateReq;
  recurring_req?: RecurringUpdateReq;
}

export enum SlotStatus {
  Success = 'success',
  InProgress = 'in progress',
  Failed = 'failed',
  Invalid = 'invalid',
  Timeout = 'timeout',
}

export enum ScheduleStatus {
  Pending = 'pending',
  Running = 'running',
  Paused = 'paused',
  Terminated = 'terminated',
  Finished = 'finished',
}

export interface InterventionSlotHistory {
  status: SlotStatus;
  detail: string;
  updated_at: string;
}

export interface SlotInfo {
  status: SlotStatus;
  detail: string;
  history: InterventionSlotHistory[];
}

export interface ScheduleRequest {
  type: ScheduleType;
  definition: ScheduleDefinition;
}

export interface Schedule extends ScheduleRequest {
  id: number;
  slots: Record<TimeRFC3999, SlotInfo>;
  ref: any;
  status: ScheduleStatus;
  Paused: boolean;
  created_by: string;
  created_at: string;
  Terminated: boolean;
}
