import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';

import { NavigationAction } from 'components/DateTime/DatetimeRangePicker/types';
import Header from 'components/DateTime/DatetimeRangePicker/Header';

import { chunks } from 'helpers/misc';
import { getDaysInMonth, isBetween } from 'helpers/dates';

import './month.scss';
import Day from '../day';

const WEEK_DAYS: string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat'];

export interface HighlightedDate {
  date: Date;
  textColor?: string;
  backgroundColor?: string;
}

interface MonthProps {
  timeOffset: string;
  currentMonth: Date;
  selectedDate: Date;
  minDate: Date;
  maxDate: Date;
  highlightedDates?: HighlightedDate[];
  setCurrentMonth: (date: Date) => void;
  helpers: {
    isHover: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (action: NavigationAction) => void;
  };
}

const Month: FC<MonthProps> = ({
  timeOffset,
  currentMonth,
  selectedDate,
  helpers,
  handlers,
  setCurrentMonth,
  minDate,
  maxDate,
  highlightedDates = [],
}) => {
  const highlightedIndexed: Record<string, HighlightedDate[]> = useMemo(
    () =>
      highlightedDates.reduce((acc, curr) => {
        const date = dayjs(curr.date).utcOffset(timeOffset);
        const key = date.format('YYYY-MM-DD');

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push();

        return acc;
      }, {} as Record<string, HighlightedDate[]>),
    [highlightedDates, timeOffset]
  );

  return (
    <div className="date-picker-month-root">
      <Header
        date={currentMonth}
        setDate={setCurrentMonth}
        nextDisabled={false}
        prevDisabled={false}
        onClickPrevious={() => handlers.onMonthNavigate(NavigationAction.Previous)}
        onClickNext={() => handlers.onMonthNavigate(NavigationAction.Next)}
      />

      <div className="month-week-days-container">
        {WEEK_DAYS.map((day) => (
          <span key={day} className="month-week-day">
            {day}
          </span>
        ))}
      </div>

      <div className="month-days-container">
        {chunks(getDaysInMonth(currentMonth), 7).map((week, index) => (
          <div key={index} className="month-week">
            {week.map((day) => {
              const isToday = dayjs(selectedDate).isSame(day, 'day');
              const highlighted = helpers.isHover(day);
              const isSelected = highlightedIndexed[dayjs(day).format('YYYY-MM-DD')];
              const colors = highlightedDates.find(
                (d) => dayjs(d.date).format('YYYY-MM-DD') === dayjs(day).format('YYYY-MM-DD')
              );

              return (
                <Day
                  key={dayjs(day).format('YYYY-MM-DD')}
                  outlined={isToday}
                  highlighted={highlighted}
                  hasEvents={!!isSelected}
                  isCurrentMonth={dayjs(day).isSame(currentMonth, 'month')}
                  disabled={!isBetween(day, minDate, maxDate)}
                  onClick={() => handlers.onDayClick(day)}
                  onHover={() => handlers.onDayHover(day)}
                  value={day}
                  color={colors?.textColor}
                  backgroundColor={colors?.backgroundColor}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Month;
