import React from 'react';
import ReactMarkdown from 'react-markdown';

import { RetrievedChunkAPI, SearchType } from 'services/upload/upload.types';

import './chunk-item.scss';

interface Props {
  chunk: RetrievedChunkAPI;
  showHeader?: boolean;
}

const ChunkItem = ({ chunk, showHeader = true }: Props) => {
  return (
    <div className="chunk-item">
      {showHeader && (
        <div className="chunk-item__header">
          <div className="title">Score: {chunk.score}</div>
          <div>{chunk.chunk_type}</div>
        </div>
      )}
      {chunk.chunk_type === SearchType.Image || chunk.chunk_type === SearchType.Table ? (
        <div>
          <img src={`data:image/png;base64, ${chunk.chunk_ref}`} />
          <div>
            <ReactMarkdown>{chunk.chunk}</ReactMarkdown>
          </div>
        </div>
      ) : (
        <div>
          <ReactMarkdown>{chunk.chunk}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};

export default ChunkItem;
