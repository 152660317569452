import React from 'react';

import { AlgorithmClass } from 'services/intervention/intervention.types';

import ModelContextProvider from './useContext';
import { useModelContext } from './useContext';

import NewEmbeddingModel from './new-embedding-model';
import NewRecommenderModel from './new-recommender-model';
import NewCensoringModel from './new-censoring-model';

import './new-model.scss';

const ModelNew = () => {
  const { definition } = useModelContext();

  if (definition?.class === AlgorithmClass.Embedding) {
    return <NewEmbeddingModel />;
  }

  if (definition?.class === AlgorithmClass.Recommender) {
    return <NewRecommenderModel />;
  }

  if (definition?.class === AlgorithmClass.Censoring) {
    return <NewCensoringModel />;
  }

  return <NewCensoringModel />;
};

export default function ModelForm() {
  return (
    <ModelContextProvider>
      <ModelNew />
    </ModelContextProvider>
  );
}
