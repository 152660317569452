import { useState, useEffect } from 'react';

import { useToast } from 'hooks';
import { useServicesContext } from 'hooks/useServicesContext';
import useCFNavigation from 'hooks/useCFNavigation';

import { AppModel, ModelId } from 'domain/model.types';

import { ToastType } from 'components/CFToast/types';

import { CFRoutes } from 'routes';

interface Props {
  id: ModelId | undefined;
}

const useModel = ({ id }: Props) => {
  const [model, setModel] = useState<AppModel>();

  const { addToast } = useToast();

  const navigate = useCFNavigation();
  const { modelService } = useServicesContext();

  const getModel = async (id: ModelId) => {
    try {
      const view = await modelService.getById(id);

      if (!view) {
        return;
      }

      setModel(view);
    } catch (e) {
      addToast(`Couldn't load model definition`, ToastType.ERROR, 5000);
      navigate(CFRoutes.model);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    getModel(id);
  }, [id]);

  return model;
};

export default useModel;
