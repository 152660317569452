import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import { TraitSubject } from 'domain/traits.types';
import { NewModelRequest, PopulationPolicy, SplitRatio } from 'domain/model.types';
import { CFRole } from 'domain/general.types';

import { getCurrentProject } from 'services/session/session.service';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import SchedulingBuilder, { SchedulingBuilderRef } from 'connected-components/SchedulingBuilder';

import Wizard from 'components/CFWizard';
import CFButton from 'components/buttons/CFButton';
import { ToastType } from 'components/CFToast/types';

import ModelCohortPicker, { ModelCohortType, SamplingType } from './cohort';
import ModelDefinition from './definition';

import { useServicesContext } from 'hooks/useServicesContext';
import { Steps, useModelContext } from './useContext';
import { cohortSelectorDescription } from './constants';

import { create as createModel } from 'services/model/model.repo';

import { useToast } from 'hooks';
import { CFRoutes } from 'routes';
import { AlgorithmClass } from 'services/intervention/intervention.types';
import RecommenderFeatures from './features/recommenderFeatures';

const NewRecommenderModel = () => {
  const { modelService } = useServicesContext();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const { definition, readiness, userCohort, features, itemCohort, fullDuration, testDuration, updateReadiness } =
    useModelContext();

  const schedulingRef = useRef<SchedulingBuilderRef>() as React.MutableRefObject<SchedulingBuilderRef>;

  const [loading, setLoading] = useState(false);

  const handleCreateModel = async () => {
    if (!userCohort || !definition || !itemCohort) {
      return;
    }

    const request: NewModelRequest = {
      schedule: schedulingRef.current.value(),
      definition: {
        name: definition.name,
        algo_spec: {
          class_name: definition.class,
          algo_name: definition.algorithm,
        },
        population_policy: itemCohort,
        data_defn: {
          recommender: {
            user_cohort_id: (userCohort as PopulationPolicy).cohort.cohort_id,
            user_extra_filters: (userCohort as PopulationPolicy).cohort.extra_filters,
            split_ratio: (userCohort as PopulationPolicy).split_ratio as SplitRatio,
            embedding_traits: features?.static || [],
            full_dur: fullDuration,
            test_dur: testDuration,
          },
        },
      },
    };

    try {
      setLoading(true);
      await createModel(request);

      addToast('Model created', ToastType.SUCCESS, 5000);

      navigate(CFRoutes.model);
    } catch (err) {
      addToast(`Impossible to create model ${(err as any).message}`, ToastType.ERROR, 5000);
      setLoading(false);

      console.log('Error creating model: ', err);
    }
  };

  return (
    <div className="new-model">
      <Wizard>
        <Wizard.Step name={Steps.Definition} ready={readiness.definition}>
          <ModelDefinition modelService={modelService} defaultClass={AlgorithmClass.Recommender} />
        </Wizard.Step>

        <Wizard.Step name={Steps.UserCohort} ready={readiness[Steps.UserCohort]} displayName="User cohort">
          <ModelCohortPicker
            showSplitRatio={false}
            type={ModelCohortType.User}
            title="User cohort"
            subtitle={definition ? cohortSelectorDescription[definition.class] : ''}
            subjectTypes={[TraitSubject.User]}
            sampling={SamplingType.None}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.ItemCohort} ready={readiness[Steps.ItemCohort]} displayName="Item cohort">
          <ModelCohortPicker
            title="Item cohort"
            subtitle="Select the items for your recommender model"
            type={ModelCohortType.Item}
            subjectTypes={getCurrentProject()?.subjects || []}
            showSplitRatio={true}
            sampling={SamplingType.None}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.Features} ready={readiness.features}>
          <RecommenderFeatures />
        </Wizard.Step>

        <Wizard.Step name={Steps.Schedule} ready={readiness.scheduling}>
          <InterventionSection name={Steps.Schedule} title="Scheduling" subtitle="Set schedules for your model.">
            <SchedulingBuilder ref={schedulingRef} onReady={(ready) => updateReadiness(ready, Steps.Schedule)} />
          </InterventionSection>
        </Wizard.Step>

        <Wizard.Footer>
          <div className="controls">
            <CFButton
              value="Create Model"
              role={CFRole.Primary}
              disabled={Object.values(readiness).some((ready) => !ready)}
              isLoading={loading}
              onClick={handleCreateModel}
              iconCustom={<img src={pagePlusIcon} />}
            />
          </div>
        </Wizard.Footer>
      </Wizard>
    </div>
  );
};

export default NewRecommenderModel;
