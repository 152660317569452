import { useEffect, useState } from 'react';

import { useServicesContext } from 'hooks/useServicesContext';
import { Schedule } from 'services/scheduling/schedulting.types.api';

interface Props {
  id: number;
}

const useScheduler = ({ id }: Props) => {
  const { schedulingService } = useServicesContext();
  const [schedule, setSchedule] = useState<Schedule>();

  useEffect(() => {
    const scheduler = schedulingService.getById(id);

    if (!scheduler) {
      return;
    }

    setSchedule(scheduler);
  }, [id]);

  useEffect(() => {
    schedulingService.subscribe(id, (schedule) => {
      setSchedule(schedule);
    });
  }, [id]);

  return schedule;
};

export default useScheduler;
