import React from 'react';

import { Model } from 'domain/model.types';
import { ColAddr } from 'domain/traits.types';

import { getIdentifierFromAddr, getTraitNameFromAddr } from 'services/traits/helpers.traits';
import Cohort from 'services/cohort/domain/Cohort';

import useCohort from 'views/segmentation/cohorts/hooks/useCohort';

import CFTraitItem from 'connected-components/traits/CFTraitItem';
import Schedule from 'connected-components/schedule';

import MonitoringSection from '../components/monitoringSection';
import ModelCohort from '../components/cohort';
import ModelTarget from '../components/target';
import ModelRuns from '../components/runs';
import ModelParameters from '../components/parameters';

import { getDurationFromNanos, Granularity } from 'helpers/dates';

interface Props {
  model: Model;
}

const DynamicFeatures = ({ addrs }: { addrs: ColAddr[] }) => {
  return (
    <>
      {addrs.map((addr) => (
        <CFTraitItem key={getIdentifierFromAddr(addr)} addr={addr} />
      ))}
    </>
  );
};

const ModelRecommenderDetails = ({ model }: Props) => {
  const userCohort = useCohort({ id: `${model.definition?.data_defn?.recommender?.user_cohort_id}` });
  const staticFeatures = model.definition.data_defn.static_features || [];

  return (
    <>
      <MonitoringSection title="Parameters">
        <ModelParameters model={model} />
      </MonitoringSection>

      <MonitoringSection title="User Cohort">
        <ModelCohort model={model} cohort={userCohort} />
      </MonitoringSection>

      <MonitoringSection title="Item Cohort">
        <ModelCohort
          model={model}
          cohort={new Cohort(model.cohort)}
          splitRatio={model.definition.population_policy.split_ratio}
        />
      </MonitoringSection>

      <MonitoringSection title="Features">
        <DynamicFeatures addrs={model.definition.data_defn.recommender?.embedding_traits as ColAddr[]} />
        <div className="field-value">
          <span>Test duration:</span>
          {getDurationFromNanos(model.definition.data_defn.recommender?.test_dur || 0, Granularity.Day)} days
        </div>
        <div className="field-value">
          <span>Full duration:</span>
          {getDurationFromNanos(model.definition.data_defn.recommender?.full_dur || 0, Granularity.Day)} days
        </div>

        {staticFeatures.length !== 0 && (
          <div className="model-features-selected">
            <div className="title">Static Features</div>
            <div className="selected-items">
              {staticFeatures.map((addr) => (
                <CFTraitItem key={getTraitNameFromAddr(addr)} addr={addr} />
              ))}
            </div>
          </div>
        )}
      </MonitoringSection>

      <MonitoringSection title="Target">
        <ModelTarget model={model} />
      </MonitoringSection>

      <MonitoringSection title="Runs">
        <ModelRuns model={model} />
      </MonitoringSection>

      <Schedule id={model.schedule.id} label="model" />
    </>
  );
};

export default ModelRecommenderDetails;
