export default class ServerSideEventBuffer {
  readingBody = false;
  responseBody = '';
  onProgress: (text: string) => void;
  onResponse: (text: string) => void;

  constructor(onProgress: (text: string) => void, onResponse: (text: string) => void) {
    this.onProgress = onProgress;
    this.onResponse = onResponse;
  }

  addChunk(chunk: string) {
    chunk
      .split('###\n')
      .filter((step) => step.trim())
      .forEach((step) => {
        try {
          if (step.startsWith('{')) {
            this.readingBody = true;
            this.responseBody = step;

            if (this.responseBody.endsWith('}')) {
              this.onResponse(this.responseBody);
            }
          } else if (this.readingBody) {
            this.responseBody += step;

            if (this.responseBody.endsWith('}')) {
              this.onResponse(this.responseBody);
            }
          } else {
            this.onProgress(step);
            return;
          }
        } catch (err) {
          this.onResponse(step);
        }
      });
  }
}
